import {
    AutocompleteArrayInput,
} from 'react-admin';
import { useWatch } from "react-hook-form";

export const TagsInput = (params) => {
    const { label, source } = params;
    const values = useWatch({ name: source });
    return <AutocompleteArrayInput fullWidth 
            source={source}
            label={label}
            choices={values ? valuesToChoices(values) : []}
            disableClearable
            freeSolo
            translateChoice={false}
            onCreate={(newTagName) => {
                return { id: newTagName, name: newTagName };
            }}
        />
};

const valuesToChoices = items => items.map(item => ({ id: item, name: item }));