import * as React from "react";
import { Admin, Resource, Layout, CustomRoutes, Authenticated, defaultTheme } from 'react-admin';
import jsonVBackProvider from './dataProvider';
import authProvider from './authProvider';
import { MyMenu } from './Menu';
import { MyAppBar } from './AppBar';
import { MyLoginPage } from './MyLoginPage';
import { UserList, UserEdit, UserCreate, UserShow } from './users';
import { GroupList, GroupEdit, GroupCreate, GroupShow } from './groups';
import { SessionList } from './sessions';
import { WEventList, WEventShow } from './wevents';
import { CamList, CamEdit, CamCreate, CamShow } from './cams';
import { EventTypeList, EventTypeEdit, EventTypeCreate, EventTypeShow } from './eventTypes';
import { CommSettList, CommSettEdit, CommSettCreate, CommSettShow } from './commSettings';
import { DetSettList, DetSettEdit, DetSettCreate, DetSettShow } from './detSettings';
import { VInteractionsList } from './vinteractions';
import { CommsList } from './communications';
import { VEventList, VEventShow, VEventCreate } from './vevents';
import * as Rights from './checkRights';
import { i18nProvider } from './i18n';
import { Route } from 'react-router-dom';
import { UserSettings } from './UserSettings';
import { ChangePass, MustChangePass } from './ChangePass';
import { LiveView } from './LiveView';
import { ShowFrame } from './ShowFrame';
import { RequestResetPassPage } from './RequestResetPass';
import { ResetPassPage } from './ResetPass';
import { DashboardRedirector } from './DashboardRedirector';
import { History } from './History';
import { UserActivatePage } from './UserActivate';

const MyLayout = (props) => {
  return <Layout {...props} 
  appBar={MyAppBar}
  menu={MyMenu} />;
};

const theme = {
  ...defaultTheme,
  sidebar: {
      width: 300,
  },
};

const App = () => {
  return <Admin 
      authProvider={authProvider}
      dataProvider={jsonVBackProvider}
      i18nProvider={i18nProvider}
      layout={MyLayout}
      theme={theme}
      loginPage={MyLoginPage}
      disableTelemetry
      dashboard={DashboardRedirector}
      >
      {permissions => [            
          <Resource name="users" 
              list={UserList}
              edit={Rights.check(permissions,Rights.EditUsersGroups) ? UserEdit : null}
              create={Rights.check(permissions,Rights.EditUsersGroups) ? UserCreate : null}
              show={Rights.check(permissions,Rights.ViewUsersGroups) ? UserShow : null}
          />,
          <Resource name="groups" 
              list={Rights.check(permissions,Rights.ViewUsersGroups) ? GroupList : null} 
              edit={Rights.check(permissions,Rights.EditUsersGroups) ? GroupEdit : null} 
              create={Rights.check(permissions,Rights.EditUsersGroups) ? GroupCreate : null}
              show={Rights.check(permissions,Rights.ViewUsersGroups) ? GroupShow : null}
          />,
          <Resource name="sessions" 
            list={Rights.check(permissions,Rights.ViewUsersGroups) ? SessionList : null} 
          />,
          <Resource name="wevents" 
            list={Rights.check(permissions,Rights.ViewUsersGroups) ? WEventList : null} 
            show={Rights.check(permissions,Rights.ViewUsersGroups) ? WEventShow : null} 
          />,

          <Resource name="cams"
            list={Rights.check(permissions,Rights.ViewCamSettings) ? CamList : null} 
            edit={Rights.check(permissions,Rights.EditCamSettings) ? CamEdit : null} 
            create={Rights.check(permissions,Rights.EditCamSettings) ? CamCreate : null}
            show={Rights.check(permissions,Rights.ViewCamSettings) ? CamShow : null}
          />,
          <Resource name="event_types"
            list={Rights.check(permissions,Rights.ViewCommSettings) ? EventTypeList : null} 
            edit={Rights.check(permissions,Rights.EditCommSettings) ? EventTypeEdit : null} 
            create={Rights.check(permissions,Rights.EditCommSettings) ? EventTypeCreate : null}
            show={Rights.check(permissions,Rights.ViewCommSettings) ? EventTypeShow : null}
          />,
          <Resource name="communication_settings"
            list={Rights.check(permissions,Rights.ViewCommSettings) ? CommSettList : null} 
            edit={Rights.check(permissions,Rights.EditCommSettings) ? CommSettEdit : null} 
            create={Rights.check(permissions,Rights.EditCommSettings) ? CommSettCreate : null}
            show={Rights.check(permissions,Rights.ViewCommSettings) ? CommSettShow : null}
          />,
          <Resource name="detection_settings"
            list={Rights.check(permissions,Rights.ViewCommSettings) ? DetSettList : null} 
            edit={Rights.check(permissions,Rights.EditCommSettings) ? DetSettEdit : null} 
            create={Rights.check(permissions,Rights.EditCommSettings) ? DetSettCreate : null}
            show={Rights.check(permissions,Rights.ViewCommSettings) ? DetSettShow : null}
          />,
          <Resource name="interactions" 
            list={Rights.check(permissions,Rights.ViewInteractions) ? VInteractionsList : null} 
          />,
          <Resource name="communications" 
            list={Rights.check(permissions,Rights.ViewCommunications) ? CommsList : null} 
          />,
          <Resource name="vevents" 
            list={Rights.check(permissions,Rights.ViewVEvents) ? VEventList : null} 
            show={Rights.check(permissions,Rights.ViewVEvents) ? VEventShow : null}
            create={Rights.check(permissions,Rights.EditVEvents) ? VEventCreate : null}
          />,
        ]}
        <CustomRoutes noLayout>
            <Route path="/requestresetpass" element={<RequestResetPassPage />} />
            <Route path="/resetpass/:token" element={<ResetPassPage />} />
            <Route path="/frame" element={<ShowFrame />} />, 
            <Route path="/mustchangepass" element={<Authenticated><MustChangePass /></Authenticated>} />,
            <Route path="/activate" element={<UserActivatePage />} />, 
        </CustomRoutes>
        <CustomRoutes>
            <Route path="/configuration" element={<Authenticated><UserSettings /></Authenticated>} />,
            <Route path="/changepass" element={<Authenticated><ChangePass /></Authenticated>} />,
            <Route path="/history/:resource/:resource_id" element={<Authenticated><History /></Authenticated>} />,
            <Route path="/live" element={<Authenticated><LiveView /></Authenticated>} />,
        </CustomRoutes>
    </Admin>
    };

export default App;