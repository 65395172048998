import { useNavigate } from 'react-router';
import { Button, useTranslate } from 'react-admin'
import {
    useRefresh,
    useNotify,
    useUnselectAll,
    useRedirect,
    useRecordContext,
} from 'ra-core';
import { stringify } from 'query-string';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import ForumIcon from '@mui/icons-material/Forum';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import GetAppIcon from '@mui/icons-material/GetApp';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { httpClient } from '../dataProvider';

export const BackButton = props => {
    const navigate = useNavigate();
    const translate = useTranslate();
    const handleClick = () => {
       navigate(-1);
    };
    return (
        <Button 
            color="primary"
            label={translate('ra.action.back')}
            onClick={handleClick}
        >
            <ChevronLeftIcon />
        </Button>
    );
};

export const BlockButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.block')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/block?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <ClearIcon/>
        </Button>
};

export const UnblockButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.unblock')}
            onClick={() => {
                const query = {
                    id: JSON.stringify(props.selectedIds),
                };
                const url = `/api/v1/${props.resource}/unblock?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok',
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <CheckIcon/>
        </Button>
};

export const DownloadButton = () => {
    const record = useRecordContext();
    return (record.v_file_id) ?
        <Button
            label={'button.downloadReport'}
            onClick={() => downloadClickHandler(record.id)}
        >
            <GetAppIcon />
        </Button>
        : null
};

const downloadClickHandler = (id) => {
    const access_token = localStorage.getItem('access_token');
    return fetch(`/api/v1/communications/${id}/download`, {
        method: 'GET',
        headers: new Headers({
            'Authorization'       : `Bearer ${access_token}`,
        }),
    }).then(response =>
        response.blob().then(blob => ({
            blob: blob,
            headers: response.headers,
        }))
    )
    .then(({ blob, headers }) => {
        const url = window.URL.createObjectURL(blob);
        var cd = headers.get("content-disposition");
        var filename = "";
        if (cd && cd.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(cd);
            if (matches != null && matches[1]) { 
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURI(filename));
        document.body.appendChild(link);
        link.click();
    })    
};

export const FindInteractionsButton = () => {
    const record = useRecordContext();
    const redirect = useRedirect();
    return (record?.v_interactions?.length) ? <Button
            label={'button.findInteractions'}
            onClick={() => {
                redirect(`/interactions?filter={"v_communication_id":"${record.id}"}`)
            }}
        >
            <ForumIcon />
        </Button>
        : null
};

export const ActivateButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.activate')}
            onClick={() => {
                const url = `/api/v1/${props.resource}/${props.selectedIds[0]}/activate`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <ToggleOnIcon/>
        </Button>
};

export const CopyButton = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(props.resource);
    const translate = useTranslate();
    return <Button color="primary"
            label={translate('button.copy')}
            onClick={() => {
                const url = `/api/v1/${props.resource}/${props.selectedIds[0]}/copy`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('button.ok', 
                        { type: 'success', messageArgs: { smart_count: props.selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <CopyAllIcon/>
        </Button>
};