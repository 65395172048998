import { useRedirect, usePermissions, useTranslate } from 'react-admin';
import * as Rights from './checkRights';
import {
    CardContent,
    Card,
} from '@mui/material';

export const DashboardRedirector = () => {
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    if (Rights.check(permissions,Rights.ViewCamsLive) || Rights.check(permissions,Rights.ViewCamsArchive)) {
        redirect('/live');
    } else if (Rights.check(permissions,Rights.ViewUsersGroups)) {
        redirect('list', 'users');
    }

    return <Card>
        <CardContent>{translate('menu.missingRedirectRights')}</CardContent>
    </Card>
};