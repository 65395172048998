import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import englishMessages from 'ra-language-english';

const vmonMessages = {
    ru: {
        resources: {
            users: {
                menu: "Пользователи",
                name: "Пользователь |||| Пользователи",
                fields: {
                    login: "Логин",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    status: "Статус",
                    w_group_id: "Группа",
                    msisdn: "Телефон",
                    email: "Email",
                    first_name: "Имя",
                    middle_name: "Отчество",
                    last_name: "Фамилия",
                    locale: "Локаль",
                    password: "Пароль",
                    is_password_reset_required: "Требуется ли смена пароля",
                    activation_link: "Ссылка для активации",
                },
                loginError: "Логин обязательный параметр",
                groupError: "Группа обязательный параметр",
                passMissing: "Пароль обязательый параметр",
                statusMissing: "Статус обязательый параметр",
                passShort: "Пароль должен быть не менее 6 символов",
                localeError: "Локаль обязательный параметр",
                id1Error: "У администратора по умолчанию нельзя менять группу",
                id1StatusError: "У администратора по умолчанию нельзя менять статус",
                title: "Пользователь: ",
                loginExistsError: "Пользователь с таким логином уже существует",
            },
            groups: {
                menu: "Группы пользователей",
                name: "Группа пользователей |||| Группы пользователей",
                fields: {
                    name: "Название",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    rights: "Права",
                },
                rightsError: "Требуется установить хотя бы одно право",
                nameError: "Имя обязательный параметр",
                id1Error: "Группу администраторов по умолчанию нельзя редактировать",
                title: "Группа пользователей: ",
            },
            sessions: {
                menu: "Сессии пользователей",
                name: "Сессия пользователя |||| Сессии пользователей",
                fields: {
                    is_active: "Активна?",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    updated_count: "Число обращений",
                    updated_last: "Последнее действие",
                    w_user_id: "Пользователь",                    
                },
                closeOk: "Сессия закрыта |||| %{smart_count} сессий закрыто",
            },
            wevents: {
                menu: "События сессий",
                name: "событий |||| События сессий", // если по сущости нет событий истории, то отображается страница с сообщением "Нет событий"
                historyTitle: "История изменений объекта",
                fields: {
                    type: "Тип события",
                    created_at: "Время",
                    w_user_id: "Пользователь",
                    w_session_id: "Сессия",
                    object_type: "Тип сущности",
                    object_ids: "ID затронутых сущностей в рамках события",
                },
                title: "Событие: ",
                desc: "Детали события",
                data: "Дополнительные данные",
            },
            cams: {
                menu: "Справочник камер",
                name: "Камера |||| Камеры",
                title: "Настройки камеры: ",
                fields: {
                    name: "Имя камеры",
                    status: "Статус",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    folder: "Папка",
                    grabber_oid: "OID в grabber узле",
                    grabber_url: "URL Grabber узла",
                    onvif_connect_string: "Параметры подключения ONVIF",
                    video_retention_period: "Срок хранения видео",
                },
                nameRequired: "Имя обязательно",
                paramRequired: "Параметр обязательный",
            },
            event_types: {
                menu: "Типы видео событий",
                name: "Тип видео событий |||| Типы видео событий",
                title: "Тип события: ",
                fields: {
                    name: "Название типа события",
                    object_classes: "Классы объектов",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                },
                nameRequired: "Имя обязательно",
            },
            communication_settings: {
                menu: "Отчеты и уведомления",
                name: "Отчеты и уведомления |||| Отчеты и уведомления",
                titleReport: "Настройки отчета: ",
                titleNotif: "Настройки уведомлений: ",
                fields: {
                    name: "Наименование",
                    status: "Статус",
                    type: "Тип",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                },
                notif: "Уведомление",
                report: "Отчет",
                nameRequired: "Наименование обязательно",
                typeRequired: "Тип обязателен",
                reportFreqDaily: "Ежедневно",
                reportFreqWeekly: "Еженедельно",
                reportFreqMonthly: "Ежемесячно",
                sectionNotif: "Настройки уведомления",
                sectionReport: "Настройки отчета",
                notifEdit1: "Если тип события:",
                notifEdit2: "...продолжается столько секунд:",
                notifEdit3: "...по любой из выбранных камер:",
                notifEdit3_1: "...то с (часов):",
                notifEdit3_2: "...и до (часов):",
                notifEdit4: "...отправить:",
                notifEdit5: "...со следующим текстом:",
                notifEdit6: "...следующим пользователям:",
                notifEdit7: "...в группу с ID:",
                notifEdit8: "...название группы:",
                notifEdit9: "...используя bot token:",
                notifEdit10: "...название бота:",
                camEventsReport: "Excel по выявленным событиям",
                reportEdit1: "Вид отчета",
                reportEdit2: "Частота отчета",
                reportEdit3: "Отправлять в: ",
                reportEdit4: "Час",
                reportEdit5: "Минуты",
                reportEdit6: "День недели",
                reportEdit7: "День месяца",
                reportEdit8: "По следующим камерам: ",
                reportEdit9: "Следующим пользователям: ",
            },
            detection_settings: {
                menu: "Настройки детектирования",
                name: "Настройки детектирования |||| Настройки детектирования",
                title: "Настройки детектирования: ",
                fields: {
                    name: "Наименование",
                    is_active: "Активны?",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    v_event_type_id: "Группа",
                },
                section_detection: "Раздел детекции",
                section_event_criteria: "Раздел критериев событий",
                edit1: "По следующим камерам: ",
                edit2: "...запрашивать детекцию по следующему URL: : ",
                edit3: "...изменив размер кадра на (разрешение по горизонтали или 'original'): ",
                edit4: "...оставлять только следующие классы объектов: ",
                editCriteria1: "По следующим камерам: ",
                editCriteria2_1: "...при срабатывании",
                editCriteria2_2: "триггеров: ",
                AND: "всех",
                OR: "любого из",
                editCriteria3: "...будет создан триггер следующего типа: ",
                editCriteria4: "...по событию следующего типа: ",
                ec1: "Класс объекта",
                ec2: "Тип присутствия",
                ec2_1: "Циклов детектирования",
                ec3: "Достоверность",
                ec4: "X",
                ec5: "Y",
                ec6: "Ширина (W)",
                ec7: "Высота (H)",
            },
            interactions: {
                menu: "Отправленные сообщения",
                name: "Сообщение |||| Сообщения",
                fields: {
                    interaction_type: "Канал",
                    is_sent: "Отправлено?",
                    last_send_attempt_at: "Время отправки",
                    created_at: "Время создания",
                    source: "От кого",
                    destination: "Кому",
                    w_user_id: "Пользователь",
                    content: "Сообщение",
                    v_communication_id: "Коммуникация",
                },
            },
            communications: {
                menu: "Отчеты и уведомления",
                name: "Отчеты и уведомления |||| Отчеты и уведомления",
                fields: {
                    v_communication_setting_id: "Категория",
                    users: "Адресаты",
                    send_at: "Когда слать",
                    created_at: "Время создания",
                    sentCount: "Разослано сообщений",
                },
                outOf: "из",
            },
            vevents: {
                menu: "События видео",
                menuCreate: "Ручной ввод событий",
                name: "Событие видео |||| События видео",
                fields: {
                    v_event_type_id: "Тип события",
                    created_at: "Время создания",
                    updated_at: "Время обновления",
                    is_finished: "Завершен?",
                    v_cam_id: "Камера",
                    folder: "Папка камеры",
                    duration: "Продолжительность",
                    v_event_triggers: "История видео события",
                },
                startTime: "Время начала",
                endTime: "Время конца",
                eventTypeError: "Тип события обязателен",
                vCamError: "Камера обязательна",
                startError: "Время начала обязательно",
                endError: "Время конца обязательно",
                endEarlier: "Конец должен быть позже начала",
                frameError: "Для данного события на сервере нет изображения"
            },
            veventtriggers: {
                fields: {
                    type: "Триггер события",
                    start_at: "Время",
                    predictions: "Служебные данные детектирования",
                },
            },
            live: {
                menu: "Просмотр видео",
                title: "Просмотр видео",
            },
        },
        menu: {
            menuSettings: "Настройки",
            menuReports: "Отчеты",
            menuVideo: "Видео",
            missingRedirectRights: "Отсутствуют права для редиректа на прикладную страницу.",
        },
        liveView: {
            selectCams: "Выберите камеры:",
        },
        misc: {
            resetPassRequestOk: "Если логин указан верно, вы получите email с инструкциями.",
            resetPassRequestFail: "Ошибка запроса на сброс пароля",
            resetPassOk: "Пароль успешно изменен",
            resetPassFail: "Ошибка обновления пароля, проверьте правильность ссылки",
            resetPassExpired: "Ссылка для смены пароля истекла",
            userActivateExpired: "Ссылка для активации пользователя истекла",
            userActivateText1: "Ваш логин для входа: ",
            userActivateText2: "Установите пароль для вашей учетной записи: ",
            setPassOk: "Пароль успешно установлен",
            active: "Активен",
            inactive: "Заблокирован",
            notactivated: "Не активирован",
            email: "Email",
            sms: "SMS",
            telegram: "Телеграм",
            monday: "Понедельник",
            tuesday: "Вторник",
            wednesday: "Среда",
            thursday: "Четверг",
            friday: "Пятница",
            saturday: "Суббота",
            sunday: "Воскресенье",
            start: "Начало",
            end: "Конец",
            required: "Поле обязательно",
            present: "Присутствует в кадре",
            notPresent: "Отсутствует в кадре",
        },
        button: {
            configuration: "Личные настройки",
            changePass: "Сменить пароль",
            setPass: "Установить пароль",
            showHistory: "История изменений",
            resetPassLink: "Вспомнить пароль",
            resetPass: "Получить письмо",
            closeSession: "Закрыть",
            showSessions: "Сессии пользователя",
            downloadReport: "Скачать отчет",
            findInteractions: "Найти сообщения",
            requestFrame: "Скачать полное изображение",
            activate: "Активировать",
            fail: 'Сервер вернул ошибку: %{code}',
            ok: 'Сущность обработана |||| %{smart_count} сущностей обработано',
            copy: "Копировать",
        },
        filter: {
            created_at_from: "Время создания от...",
            created_at_to: "Время создания до...",
            updated_at_from: "Время обновления от...",
            updated_at_to: "Время обновления до...",
            last_send_attempt_at_from: "Время отправки от...",
            last_send_attempt_at_to: "Время отправки до...",
        },
        user: {
            settings: "Настройки пользователя",
            language: "Язык интерфейса",
            first_name: "Имя",
            middle_name: "Отчество",
            last_name: "Фамилия",
            msisdn: "Номер телефона",
            email: "Email",
            updateOk: "Настройки успешно обновлены",
            updateFail: "Ошибка обновления настроек",
            msisdnError: "Неверный формат номера",
            changePass: "Смена пароля",
            oldPass: "Текущий пароль",
            newPass: "Новый пароль",
            pass: "Пароль",
            repeatPass: "Повторите пароль",
            repeatNewPass: "Повторите новый пароль",
            changePassOk: "Пароль успешно обновлен",
            changePassFail: "Ошибка обновления пароля",
            passSameError: "Новый пароль должен отличаться от текущего",
            passDontMatchError: "Введенные пароли не совпадают",
            wrongPass: "Пароль указан неверно",
        },
        weventtypes: {
            id1: "Логин пользователя",
            id2: "Выход пользователя",
            id3: "Создание пользователя",
            id4: "Редактирование пользователя",
            id5: "Удаление пользователя",
            id6: "Блокировка пользователя",
            id7: "Разблокировка пользователя",
            id8: "Смена пароля пользователем",
            id9: "Создание группы пользователей",
            id10: "Редактирование группы пользователей",
            id11: "Удаление группы пользователей",
            id12: "Закрытие сеанса пользователя",
            id13: "Создание заказчика",
            id14: "Редактирование заказчика",
            id15: "Удаление заказчика",
            id16: "Создание типа видео-события",
            id17: "Редактирование типа видео-события",
            id18: "Удаление типа видео-события",
            id19: "Создание камеры",
            id20: "Редактирование камеры",
            id21: "Удаление камеры",
            id22: "Выключение камеры",
            id23: "Включение камеры",
            id24: "Создание настроек отчетов\\уведомлений",
            id25: "Редактирование настроек отчетов\\уведомлений",
            id26: "Удаление настроек отчетов\\уведомлений",
            id27: "Создание видео события",
            id28: "Обновление пользователем своих данных",
            id29: "Запрос сброса пароля",
            id30: "Сброс пароля пользователем",
            id31: "Сессия пользователя истекла",
            id32: "Скачивание файла",
            id33: "Сессия принудительно закрыта",
            id34: "Создание настроек детектирования",
            id35: "Редактирование настроек детектирования",
            id36: "Удаление настроек детектирования",
            id37: "Активация настроек детектирования",
            id38: "Копирование настроек детектирования",
            id39: "Деактивация настроек детектирования",
            id40: "Активация пользователя",
        },
        rights: {
            id1: "Просмотр пользователей и групп",
            id2: "Редактирование пользователей и групп",
            id3: "Просмотр настроек камер",
            id4: "Редактирование настроек камер",
            id5: "Просмотр настроек отчетов, уведомлений, детектироваия",
            id6: "Редактирование настроек отчетов, уведомлений, детектирования",
            id7: "Просмотр отчетов и уведомлений",
            id8: "Просмотр событий видео мониторинга",
            id9: "Просмотр сообщений",
            id10: "Ручной ввод и редактирование событий мониторинга",
            id11: "Просмотр видеоряда с камер",
            id12: "Просмотр видеоархива",
            id13: "Редактирование заказчиков",
        }
    },
    en: {
        resources: {
            users: {
                menu: "Users",
                name: "User |||| Users",
                fields: {
                    login: "Login",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    status: "Status",
                    w_group_id: "Group",
                    msisdn: "Phone",
                    email: "Email",
                    first_name: "Name",
                    middle_name: "Middle name",
                    last_name: "Last name",
                    locale: "Locale",
                    password: "Password",
                    is_password_reset_required: "Password change required",
                    activation_link: "User activation link",
                },
                loginError: "Login is required",
                groupError: "Group is required",
                passMissing: "Password is required",
                statusMissing: "Status is required",
                passShort: "Password length should be at least 6",
                localeError: "Locale is required",
                id1Error: "Cannot edit group for default admin",
                id1StatusError: "Cannot edit status for default admin",
                title: "User: ",
                loginExistsError: "User with provided login already exists",
            },
            groups: {
                menu: "User groups",
                name: "User group |||| User groups",
                fields: {
                    name: "Name",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    rights: "Rights",
                },
                rightsError: "At least 1 right is required",
                nameError: "Name is required",
                id1Error: "Default admin group cannot be edited",
                title: "User group: ",
            },
            sessions: {
                menu: "User sessions",
                name: "User session |||| User sessions",
                fields: {
                    is_active: "Active?",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    updated_count: "Updates count",
                    updated_last: "Last action",
                    w_user_id: "User",                    
                },
                closeOk: 'Session closed |||| %{smart_count} sessions closed',
            },
            wevents: {
                menu: "Session events",
                name: "Session event |||| Session events",
                historyTitle: "Change history",
                fields: {
                    type: "Event type",
                    created_at: "Timestamp",
                    w_user_id: "User",
                    w_session_id: "Session",
                    object_type: "Object type",
                    object_ids: "Object IDs affected by the event",
                },
                title: "Event: ",
                desc: "Event details",
                data: "Additional data",
            },
            cams: {
                menu: "Cam settings",
                name: "Cam settings |||| Cam settings",
                title: "Cam: ",
                fields: {
                    name: "Cam name",
                    status: "Status",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    folder: "Folder",
                    grabber_oid: "OID in grabber node",
                    grabber_url: "Grabber URL",
                    onvif_connect_string: "ONVIF connection string",
                    video_retention_period: "Video retention period",
                },
                nameRequired: "Name is required",
                paramRequired: "Parameter is required",
            },
            event_types: {
                menu: "Video event types",
                name: "Video event type |||| Video event types",
                title: "Video event type: ",
                fields: {
                    name: "Event type name",
                    object_classes: "Object classes",
                    created_at: "Created at",
                    updated_at: "Updated at",
                },
                nameRequired: "Name is required",
            },
            communication_settings: {
                menu: "Reports and notifications",
                name: "Reports and notifications |||| Reports and notifications",
                titleReport: "Report settings: ",
                titleNotif: "Notification settings: ",
                fields: {
                    name: "Name",
                    status: "Status",
                    type: "Type",
                    created_at: "Created at",
                    updated_at: "Updated at",
                },
                notif: "Notification",
                report: "Report",
                nameRequired: "Name is required",
                typeRequired: "Type is required",
                reportFreqDaily: "Daily",
                reportFreqWeekly: "Weekly",
                reportFreqMonthly: "Monthly",
                sectionNotif: "Notification settings",
                notifEdit1: "If event type:",
                notifEdit2: "...last for so many seconds:",
                notifEdit3: "...on any of the selected cams:",
                notifEdit3_1: "...then from (hours):",
                notifEdit3_2: "...until (hours):",
                notifEdit4: "...send:",
                notifEdit5: "...with following text:",
                notifEdit6: "...to following users:",
                camEventsReport: "Detected events excel report",
                reportEdit1: "Report type",
                reportEdit2: "Report frequency",
                reportEdit3: "Send at: ",
                reportEdit4: "Hour",
                reportEdit5: "Minute",
                reportEdit6: "Week day",
                reportEdit7: "Month day",
                reportEdit8: "Include following cams: ",
                reportEdit9: "To the following users: ",
            },
            detection_settings: {
                menu: "Detection settings",
                name: "Detection settings |||| Detection settings",
                title: "Detection settings: ",
                fields: {
                    name: "Name",
                    is_active: "Active?",
                    created_at: "Created at",
                    updated_at: "Updated at",
                },
                section_detection: "Detection section",
                section_event_criteria: "Event criteria section",
                edit1: "For the following cams:",
                edit2: "...perform detection using URL: : ",
                edit3: "...resizing frame to (horizontal resolution or 'original'): ",
                edit4: "...filter following object classes: ",
                editCriteria1: "For the following cams:",
                editCriteria2_1: "...if",
                editCriteria2_2: "of the criteria is true: ",
                AND: "all",
                OR: "any",
                editCriteria3: "...the follwoing trigger will be created: ",
                editCriteria4: "...on the follwoing event type: ",
                ec1: "Object class",
                ec2: "Presence type",
                ec2_1: "Detection rounds",
                ec3: "Confidence",
                ec4: "X",
                ec5: "Y",
                ec6: "Width",
                ec7: "Height",
            },
            interactions: {
                menu: "Sent messages",
                name: "Message |||| Messages",
                fields: {
                    interaction_type: "Channel",
                    is_sent: "Is sent?",
                    last_send_attempt_at: "Send time",
                    created_at: "Created at",
                    source: "From",
                    destination: "To",
                    w_user_id: "User",
                    content: "Message",
                    v_communication_id: "Communication",
                },
            },
            communications: {
                menu: "Reports and notifications",
                name: "Reports and notifications |||| Reports and notifications",
                fields: {
                    v_communication_setting_id: "Category",
                    users: "Send to",
                    send_at: "Send at",
                    created_at: "Created at",
                    sentCount: "Sent messages",
                },
                outOf: "of",
            },
            vevents: {
                menu: "Video events",
                menuCreate: "Manual event entry",
                name: "Video event |||| Video events",
                fields: {
                    v_event_type_id: "Event type",
                    created_at: "Created at",
                    updated_at: "Updated at",
                    is_finished: "Is finished?",
                    v_cam_id: "Cam",
                    folder: "Cam folder",
                    duration: "Duration",
                    v_event_triggers: "Video event history",
                },
                startTime: "Start time",
                endTime: "End time",
                eventTypeError: "Event type is mandatory",
                vCamError: "Cam is mandatory",
                startError: "Start time is mandatory",
                endError: "End time is mandatory",
                endEarlier: "End time should be later than start",
                frameError: "There is no picture on the server for this event"
            },
            veventtriggers: {
                fields: {
                    type: "Video event trigger",
                    start_at: "Timestamp",
                    predictions: "Predictions",
                },
            },
            live: {
                menu: "Live view",
                title: "Live view",
            },
        },
        menu: {
            menuSettings: "Settings",
            menuReports: "Reports",
            menuVideo: "Video",
            missingRedirectRights: "Missing rights to redirect to target page.",
        },
        liveView: {
            selectCams: "Select cams:",
        },
        misc: {
            resetPassRequestOk: "If the provided login exists, you will receive an email.",
            resetPassRequestFail: "Error requesting password reset",
            resetPassOk: "Password changed successfully",
            resetPassFail: "Error changing password, did you use wrong URL?",
            resetPassExpired: "Reset password link has expired",
            userActivateExpired: "User activation link has expired",
            userActivateText1: "Your login is: ",
            userActivateText2: "Set password for your account: ",
            setPassOk: "Password was set",
            active: "Active",
            inactive: "Blocked",
            notactivated: "Not activated",
            email: "Email",
            sms: "SMS",
            telegram: "Telegram",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            start: "Start",
            end: "End",
            required: "Field is required",
            present: "Present on frame",
            notPresent: "Not present on frame",
        },
        button: {
            configuration: "Personal settings",
            changePass: "Change password",
            setPass: "Set password",
            showHistory: "Change history",
            resetPassLink: "Reset pass",
            resetPass: "Get email with link",
            closeSession: "Close",
            showSessions: "User sessions",
            downloadReport: "Download report",
            findInteractions: "Find messages",
            requestFrame: "Download full image",
            activate: "Activate",
            fail: 'Server returned error: %{code}',
            ok: 'Entity processed |||| %{smart_count} entities processed',
            copy: "Copy",
        },
        filter: {
            created_at_from: "Created from...",
            created_at_to: "Created to...",
            updated_at_from: "Updated from...",
            updated_at_to: "Updated to...",
            last_send_attempt_at_from: "Send time from...",
            last_send_attempt_at_to: "Send time to...",
        },
        user: {
            settings: "User settings",
            language: "Interface language",
            first_name: "First name",
            middle_name: "Middle name",
            last_name: "Last name",
            msisdn: "Phone",
            email: "Email",
            updateOk: "Profile has been updated",
            updateFail: "Error updating profile",
            msisdnError: "Wrong phone number format",
            changePass: "Change password",
            oldPass: "Current password",
            newPass: "New password",
            pass: "Password",
            repeatPass: "Repeat password",
            repeatNewPass: "Repeat new password",
            changePassOk: "Password changed",
            changePassFail: "Password change unsuccessful",
            passSameError: "New password must be different",
            passDontMatchError: "Entered passwords do not match",
            wrongPass: "Wrong password",
        },
        weventtypes: {
            id1: "User login",
            id2: "User logout",
            id3: "Create user",
            id4: "Edit user",
            id5: "Delete user",
            id6: "Block user",
            id7: "Unblock user",
            id8: "User changed passsword",
            id9: "Create user group",
            id10: "Edit user group",
            id11: "Delete user group",
            id12: "Close user session",
            id13: "Create customer",
            id14: "Edit customer",
            id15: "Delete customer",
            id16: "Create v_event type",
            id17: "Edit v_event type",
            id18: "Delete v_event type",
            id19: "Create cam",
            id20: "Edit cam",
            id21: "Delete cam",
            id22: "Disable cam",
            id23: "Enable cam",
            id24: "Create communication settings",
            id25: "Edit communication settings",
            id26: "Delete communication settings",
            id27: "Create v_event",
            id28: "User self update",
            id29: "Reset password request",
            id30: "User reset password",
            id31: "User session expired",
            id32: "File download",
            id33: "Sesion closed",
            id34: "Create detection settings",
            id35: "Edit detection settings",
            id36: "Delete detection settings",
            id37: "Activate detection settings",
            id38: "Copy detection settings",
            id39: "Deactivate detection settings",
            id40: "Activate user",
        },
        rights: {
            id1: "View users and groups",
            id2: "Edit users and groups",
            id3: "View cam settings",
            id4: "Edit cam settings",
            id5: "View report, notification and detection settings",
            id6: "Edit report, notification and detection settings",
            id7: "View reports and notifications",
            id8: "View video events",
            id9: "View messages",
            id10: "Video events manual entry and edit",
            id11: "View cam livestream",
            id12: "View video archive",
            id13: "Edit customers",
        }
    },
};

const messages = {
    ru: { ...russianMessages, ...vmonMessages.ru },
    en: { ...englishMessages, ...vmonMessages.en },
};

export const i18nProvider = polyglotI18nProvider(locale => messages[locale], "ru");