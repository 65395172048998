import { List,
    AutocompleteInput,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    SelectField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    BooleanField,
    SelectInput,
    TextInput,
    BooleanInput,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { InteractionTypeChoices } from './Constants'
import { Pagination500 } from './components/Pagination'

export const VInteractionsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.destination}
                    secondaryText={record => `${translate('resources.interactions.fields.last_send_attempt_at')}: ${record.last_send_attempt_at}`}
                    tertiaryText={record => <BooleanField source="is_sent" basePath="interactions" record={record} />}
                    linkType={false}
                />
            ) : (
                
            <Datagrid bulkActionButtons={false}> 
                <SelectField source="interaction_type" choices={InteractionTypeChoices} />
                <BooleanField source="is_sent" />
                <DateField source="last_send_attempt_at" locales="ru-RU" showTime />
                <TextField source="source" sortable={false} />
                <TextField source="destination" sortable={false} />
                <ReferenceField source="w_user_id" reference="users" link={false}>
                    <TextField source="login" />
                </ReferenceField>
                <TextField source="content" sortable={false} />
                <DateField source="created_at" locales="ru-RU" showTime />
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <SelectInput source="interaction_type" choices={InteractionTypeChoices} />,
    <BooleanInput source="is_sent" />,
    <ReferenceInput source="w_user_id" reference="users" filterToQuery={searchText => ({ login: searchText })} >
        <AutocompleteInput optionText="login" />
    </ReferenceInput>,
    <TextInput source="source" />,
    <TextInput source="destination" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.last_send_attempt_at_from'} source="last_send_attempt_at_from" />,
    <DateTimeInput label={'filter.last_send_attempt_at_to'} source="last_send_attempt_at_to" />,
    <TextInput source="v_communication_id" />,
];


