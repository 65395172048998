import { List,
    SelectInput,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    BooleanInput,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    BooleanField,
    usePermissions,
} from 'react-admin';
import { httpClient } from './dataProvider';
import * as Rights from './checkRights';
import { useMediaQuery, Button } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { stringify } from 'query-string';
import {
    useRefresh,
    useNotify,
    useUnselectAll,
    useListContext,
} from 'ra-core';
import { Pagination500 } from './components/Pagination'

export const SessionList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <ReferenceField source="w_user_id" reference="users" link={false} basePath="users" record={record}>
                        <TextField source="login" />
                    </ReferenceField>}
                    secondaryText={record => `${translate('resources.sessions.fields.updated_at')}: ${record.updated_at}`}
                    tertiaryText={record => <BooleanField source="is_active" basePath="sessions" record={record} />}
                    //linkType={(record, id) => {return `/wevents?filter={"w_session_id":"${record.id}"}`}} //should work starting from react-admin v3.19 
                />
            ) : (
            <Datagrid bulkActionButtons={Rights.check(permissions,Rights.EditUsersGroups) ? <DefaultBulkActionButtons /> : false }
                rowClick={(id, basePath, record) => {
                    return `/wevents?filter={"w_session_id":"${record.id}"}`
                }}>
                <TextField source="id" />
                <ReferenceField source="w_user_id" reference="users" link={false}>
                    <TextField source="login" />
                </ReferenceField>
                <BooleanField source="is_active" />
                <TextField source="updated_count" />
                <TextField source="updated_last" sortable={false} />
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />                
            </Datagrid> )}
        </List>
    )
};

const DefaultBulkActionButtons = props => {
    //console.log('DefaultBulkActionButtons props'+JSON.stringify(props));
    const translate = useTranslate();
    const refresh = useRefresh();
    const { selectedIds } = useListContext(props);
    //console.log("DefaultBulkActionButtons selectedIds: ", selectedIds, " data: ", data);
    const notify = useNotify();
    const unselectAll = useUnselectAll('sessions');
    const style = {
        marginLeft: '5px',
    };
    return <>
        <Button color="primary" 
            onClick={() => {
                //send post to close selected session
                const query = {
                    id: JSON.stringify(selectedIds),
                };
                const url = `/api/v1/sessions/close?${stringify(query)}`;
                return httpClient(url, {
                    method: 'POST',
                }).then(({ status, json }) => {
                    refresh();
                    notify('resources.sessions.closeOk', 
                        { type: 'success', messageArgs: { smart_count: selectedIds.length } }
                    ); 
                    unselectAll(); 
                }).catch(e => {
                    if(e.status !== 200) {
                        notify('button.fail', 
                            { type: 'warning', messageArgs: { code: e.status} });
                    }
                });
            }} >
            <RemoveRedEyeIcon /><div style={style}>{translate('button.closeSession')}</div>
        </Button>
    </>
};

const customFilter = [
    <BooleanInput source="is_active" />,
    <ReferenceInput source="w_user_id" reference="users" filterToQuery={searchText => ({ login: searchText })} >
        <SelectInput optionText="login" />
    </ReferenceInput>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];