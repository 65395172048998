import { List,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    SelectInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    FunctionField,
    useRecordContext,
} from 'react-admin';
import { Box, useMediaQuery } from '@mui/material';
import { DownloadButton, FindInteractionsButton } from './components/Buttons'
import { Pagination500 } from './components/Pagination'

export const CommsList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList 
                    primaryText={record => <ReferenceField source="v_communication_setting_id" reference="communication_settings" link={false} basePath="communications" record={record}>
                        <TextField source="name" />
                    </ReferenceField>}
                    secondaryText={record => `${translate('resources.communication_settings.fields.created_at')}: ${record.created_at}`}
                    linkType={false}
                />
            ) : (
            <Datagrid bulkActionButtons={false}> 
                <ReferenceField source="v_communication_setting_id" reference="communication_settings" link={false} sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="created_at" locales="ru-RU" showTime />
                <Addressee label="resources.communications.fields.users"/>
                
                <FunctionField
                    label="resources.communications.fields.sentCount"
                    render={record => {
                        let totalI = record?.v_interactions?.length || 0;
                        let sentI = 0;
                        // считаем число interactions у которых is_sent
                        record?.v_interactions?.forEach((x, i) => {
                            if (x.is_sent) {
                                sentI = sentI + 1;
                            }});
                        return `${sentI} ${translate('resources.communications.outOf')} ${totalI}`}
                    }
                />;
                <FindInteractionsButton/>
                <DownloadButton/>
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <ReferenceInput source="v_communication_setting_id" reference="communication_settings" >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
];

const Addressee = () => {
    const record = useRecordContext();
    // если у коммуникации есть юзеры, это точно смс или имейл, отображаем карточки юзеров
    if (record.w_users) {
        return <ArrayField source="w_users" sortable={false}>
            <SingleFieldList linkType={false}>
                <ChipField source="login" />
            </SingleFieldList>
        </ArrayField>
    } else {
        // если у коммуникации нет юзеров, то надо проверить, не являются ли ее interactions телеграм сообщениями
        if (record.v_interactions && (record.v_interactions[0].interaction_type === 3)){
            return <Box>{record.v_interactions[0].destination}</Box>
        }
    }
    return null
};





