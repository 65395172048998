import { List,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    Edit, 
    Create,
    Show,
    SimpleForm,
    SelectField,
    TextInput,
    DateTimeInput,
    useTranslate,
    NumberInput,
    usePermissions,
    SimpleShowLayout,  
    SelectInput,  
    ReferenceInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    FormDataConsumer,
    ReferenceField,
    NumberField,
    ReferenceArrayField,
    SingleFieldList,
    Labeled,
    required,
    minValue,
    BooleanField,
    BooleanInput,
    useListContext,
    ArrayInput,
    SimpleFormIterator,
    RemoveItemButton,
    ChipField,
    ArrayField,
    FunctionField,
    RadioButtonGroupInput,
} from 'react-admin';
import * as Rights from './checkRights';
import { ActivateButton, CopyButton } from './components/Buttons'
import { TagsInput } from './components/TagsInput'
import { Box, Typography, useMediaQuery } from '@mui/material';
import { HistoryButtonToolbar } from './components/Toolbars'
import { NamedTitle } from './components/Title'
import { TriggerTypeChoices, PresenceTypeChoices } from './Constants'
import { Pagination500 } from './components/Pagination'

export const DetSettList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List 
            exporter={false}
            filters={customFilter} 
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => <BooleanField source="is_active" record={record}/>}
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditCommSettings) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditCommSettings) ? "edit" : "show"}>
                <TextField source="name" />
                <BooleanField source="is_active" />
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />                
            </Datagrid> )}
        </List>
    )
};

const DefaultBulkActionButtons = props => {
    const { data } = useListContext();
    //console.log("data: ", data, " selectedIds: ", props.selectedIds);
    // рисуем кнопку активировать только если выделены 1 настройки...
    let showActivate = (props.selectedIds.length < 2);
    if (!showActivate) {return <></>}
    // ... и они не активны
    for (let i = 0; i < data.length ; i++) {
        if (props.selectedIds.includes(data[i].id) && data[i].is_active) {
            showActivate = false;
            break;
        }
    }
    // рисуем кнопку копировать только если выделены 1 настройки...
    let showCopy = (props.selectedIds.length < 2);
    return <>
        { showActivate ? <ActivateButton {...props} /> : null}
        { showCopy ? <CopyButton {...props} /> : null}
    </>
};

const customFilter = [
    <TextInput source="name" />,
    <BooleanInput source="is_active" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];
//------------
export const DetSettEdit = () => {
    return <Edit title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>} mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput disabled source="id" />
            <Labeled><BooleanField source="is_active" /></Labeled>
            <TextInput source="name" validate={[required()]} />
            <DataElements/>
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const EventCriteriaArray = (props) => {
    const { source } = props
    return <ArrayInput label={false} source={source}>
        <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
            <FormDataConsumer>
            {({ getSource, scopedFormData }) => { return <>
                <Box display="flex" sx={{width: 1}}>
                    <Box flex={1} mr={'1em'}>
                        <TextInput fullWidth source={getSource("object_class")} label="resources.detection_settings.ec1"/>
                    </Box>
                    <Box flex={1} mr={'1em'}>
                        <SelectInput source={getSource("presence_type")}
                            fullWidth
                            choices={PresenceTypeChoices}
                            validate={[required()]}
                            label="resources.detection_settings.ec2"/>
                    </Box>
                    <Box flex={1} mr={'1em'}>
                        <NumberInput fullWidth source={getSource("detection_rounds")} label="resources.detection_settings.ec2_1" validate={[minValue(1)]}/>
                    </Box>
                    <Box flex={1}>
                        <NumberInput fullWidth source={getSource("confidence")} label="resources.detection_settings.ec3"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr={'1em'}><NumberInput fullWidth source={getSource("x")} label="resources.detection_settings.ec4"/></Box>
                    <Box flex={1} mr={'1em'}><NumberInput fullWidth source={getSource("y")} label="resources.detection_settings.ec5"/></Box>
                    <Box flex={1} mr={'1em'}><NumberInput fullWidth source={getSource("width")} label="resources.detection_settings.ec6"/></Box>
                    <Box flex={1} mr={'1em'}><NumberInput fullWidth source={getSource("height")} label="resources.detection_settings.ec7"/></Box>
                </Box></>
            }}
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>
}

const DataElements = () => {
    const translate = useTranslate();
    return <>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.detection_settings.section_detection')}
            </Typography>
            <ArrayInput label={false} source="detection_setting">
                <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
                    <FormDataConsumer>
                    {({
                        formData, // The whole form data
                        scopedFormData, // The data for this item of the ArrayInput
                        getSource, // A function to get the valid source inside an ArrayInput
                        ...rest
                    }) => {
                        return <>
                            <ReferenceArrayInput source={getSource('v_cam_id')} reference="cams">
                                <CheckboxGroupInput optionText="name" label="resources.detection_settings.edit1" />
                            </ReferenceArrayInput>
                            <TextInput fullWidth source={getSource('inference_url')} label="resources.detection_settings.edit2"/>
                            <TextInput fullWidth source={getSource('resolution')} label="resources.detection_settings.edit3"/>
                            <TagsInput source={getSource('object_class')} label="resources.detection_settings.edit4"/>
                        </>
                    }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
        <Box p='1em 1em 1em 1em' ></Box>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.detection_settings.section_event_criteria')}
            </Typography>
            <ArrayInput label={false} source="event_criteria_list">
                <SimpleFormIterator removeButton={<RemoveItemButton label={null}/>}>
                    <FormDataConsumer>
                    {({
                        formData, // The whole form data
                        scopedFormData, // The data for this item of the ArrayInput
                        getSource, // A function to get the valid source inside an ArrayInput
                        ...rest
                    }) => {
                        return <>
                            <ReferenceArrayInput source={getSource('v_cam_id')} reference="cams">
                                <CheckboxGroupInput optionText="name" label="resources.detection_settings.editCriteria1" />
                            </ReferenceArrayInput>
                            <Box 
                                display="flex" 
                                alignItems="center"
                                sx={{width: 1}}>
                                <Box flex={1} ><Typography variant="body1">{translate('resources.detection_settings.editCriteria2_1')}</Typography></Box>
                                <Box flex={1} ><RadioButtonGroupInput defaultValue="OR" helperText={false} label={false} source={getSource("predicate")} choices={[
                                    { id: 'OR', name: 'resources.detection_settings.OR' },
                                    { id: 'AND', name: 'resources.detection_settings.AND' },
                                ]} /></Box>
                                <Box flex={1}><Typography variant="body1">{translate('resources.detection_settings.editCriteria2_2')}</Typography></Box>
                            </Box>
                            <EventCriteriaArray source={getSource("event_criteria")}/>
                            <SelectInput source={getSource('v_event_trigger_type')}
                                fullWidth
                                choices={TriggerTypeChoices} 
                                validate={[required()]}
                                label="resources.detection_settings.editCriteria3"
                                />
                            <ReferenceInput source={getSource('v_event_type_id')} reference="event_types">
                                <SelectInput fullWidth optionText="name" label="resources.detection_settings.editCriteria4" validate={[required()]}/>
                            </ReferenceInput>
                        </>
                    }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </>
};
//------------
export const DetSettCreate = () => {
    return <Create redirect='list'>
        <SimpleForm>
            <TextInput source="name" validate={[required()]}/>
            <DataElements/>
        </SimpleForm>
    </Create>
};
//------------
export const DetSettShow = () => {
    return <Show title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="is_active" />
            <ShowDataElements/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const ShowDataElements = () => {
    const translate = useTranslate();
    return <>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.detection_settings.section_detection')}
            </Typography>
            <ArrayField source="detection_setting">
                <Datagrid bulkActionButtons={false}>
                    <Box>
                        <ReferenceArrayField label={false} source='v_cam_id' reference="cams" >
                            <Labeled><SingleFieldList label="resources.detection_settings.edit1" linkType={false}>
                                        <ChipField source="name" />
                                    </SingleFieldList></Labeled>
                        </ReferenceArrayField>
                        <Labeled><TextField source='inference_url' label="resources.detection_settings.edit2"/></Labeled>
                        <Box mr={'1em'}></Box>
                        <Labeled><TextField source='resolution' label="resources.detection_settings.edit3"/></Labeled>
                        <Box mr={'1em'}></Box>
                        <ArrayField source='object_class'>
                        <Labeled><SingleFieldList linkType={false} label="resources.detection_settings.edit4">
                                <FunctionField render={(record) => (
                                    <ChipField record={{name: record }} source="name" />
                                )} />
                            </SingleFieldList></Labeled>
                        </ArrayField>
                    </Box>
                </Datagrid>
            </ArrayField>
        </Box>
        <Box p='1em 1em 1em 1em' ></Box>
        <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 1200 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.detection_settings.section_event_criteria')}
            </Typography>
            <ArrayField source="event_criteria_list">
                <Datagrid bulkActionButtons={false}>
                    <Box>
                        <ReferenceArrayField label={false} source='v_cam_id' reference="cams" >
                            <Labeled><SingleFieldList label="resources.detection_settings.editCriteria1" linkType={false}>
                                        <ChipField source="name" />
                                    </SingleFieldList></Labeled>
                        </ReferenceArrayField>
                        <Typography variant="body1">{translate('resources.detection_settings.editCriteria2')}</Typography>
                        <EventCriteriaArrayField source="event_criteria"/>
                        <Labeled><SelectField source="v_event_trigger_type" 
                            choices={TriggerTypeChoices}
                            label="resources.detection_settings.editCriteria3"
                            /></Labeled>
                        <Box mr={'1em'}></Box>
                        <ReferenceField source='v_event_type_id' reference="event_types" link={false}>
                            <Labeled><TextField label="resources.detection_settings.editCriteria4" source="name"/></Labeled>
                        </ReferenceField>
                    </Box>
                </Datagrid>
            </ArrayField>
        </Box>
    </>
}

const EventCriteriaArrayField = (props) => {
    const { source } = props
    return <ArrayField label={false} source={source}>
        <Datagrid bulkActionButtons={false}>
            <Box display="flex" sx={{width: 1}}>
                <Box flex={1} mr={'1em'}>
                    <TextField fullWidth source="object_class" label="resources.detection_settings.ec1"/>
                </Box>
                <Box flex={1} mr={'1em'}>
                    <Labeled><SelectField source="presence_type"
                        choices={PresenceTypeChoices}
                        label="resources.detection_settings.ec2"/></Labeled>
                </Box>
                <Box flex={1}>
                <Labeled><NumberField fullWidth source="confidence" label="resources.detection_settings.ec3"/></Labeled>
                </Box>
            </Box>
            <Box display="flex">
                <Box flex={1} mr={'1em'}><Labeled><NumberField fullWidth source="x" label="resources.detection_settings.ec4"/></Labeled></Box>
                <Box flex={1} mr={'1em'}><Labeled><NumberField fullWidth source="y" label="resources.detection_settings.ec5"/></Labeled></Box>
                <Box flex={1} mr={'1em'}><Labeled><NumberField fullWidth source="width" label="resources.detection_settings.ec6"/></Labeled></Box>
                <Box flex={1} mr={'1em'}><Labeled><NumberField fullWidth source="height" label="resources.detection_settings.ec7"/></Labeled></Box>
            </Box>
       </Datagrid>
    </ArrayField>
}