import React, { useState, useCallback } from 'react';
import { useTranslate, PasswordInput, SimpleForm, useNotify, Title, useRedirect, SaveButton, Toolbar  } from 'react-admin';
import {
    Box,
    Button,
    CardContent,
    Card,
    CircularProgress,
    CardActions,
} from '@mui/material';
import { useNavigate } from 'react-router';

export const ChangePass = () => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const navigate = useNavigate();

    const handleChangePass = useCallback(values => {
        setLoading(true);
        
        const access_token = localStorage.getItem('access_token');
        const request = new Request('/api/v1/users/change_pass', {
            method: 'POST',
            body: JSON.stringify({ 
                password: values.password, 
                'new_password': values.newPassword,
            }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${access_token}`}),
        });

        // we use fetch instead of httpClient because we need to differentiate error types
        fetch(request).then(response => {
            setLoading(false);
            if (response.status === 200) {
                notify('user.changePassOk', { type: 'success' });
                navigate(-1);
            } else if(response.status === 401) {
                notify('user.wrongPass', { type: 'warning' });
            } else {
                notify('user.changePassFail', { type: 'warning' });    
            }
        }).catch(e => {
            setLoading(false);
            notify('user.changePassFail', { type: 'warning' });
        })
    });

    return (
        <SimpleForm onSubmit={handleChangePass} toolbar={<CustomToolbar />} validate={validateForm}>
            <Card>
               <Title title={'user.changePass'} />
                <CardContent>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.oldPass'} fullWidth source="password" />
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.newPass'} fullWidth source="newPassword" />
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.repeatNewPass'} fullWidth source="repeatNewpassword" /> 
                    </Box>
                </CardContent>
            </Card>
        </SimpleForm>
)};

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const validateForm = (values) => {
    const errors = {};
    if ((!values.newPassword) || (values.newPassword === ""))
        errors.newPassword = 'misc.required';
    if (values.newPassword?.length < 6 )
        errors.newPassword = 'resources.users.passShort';
    if (values.password === values.newPassword)
        errors.newPassword = 'user.passSameError';
    if (values.newPassword !== values.repeatNewpassword)
        errors.repeatNewpassword = 'user.passDontMatchError';
    return errors
};

export const MustChangePass = () => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate();

    const submit = values => {
        setLoading(true);
        
        const access_token = localStorage.getItem('access_token');
        const request = new Request('/api/v1/users/change_pass', {
            method: 'POST',
            body: JSON.stringify({ 
                password: values.password, 
                'new_password': values.newPassword,
            }),
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${access_token}`}),
        });

        // we use fetch instead of httpClient because we need to differentiate error types
        fetch(request)
        .then(response => {
            setLoading(false);
            if (response.status === 200) {
                notify('user.changePassOk', { type: 'success' });
                redirect(`/`);
            } else if(response.status === 401) {
                notify('user.wrongPass', { type: 'warning' });
            } else {
                notify('user.changePassFail', { type: 'warning' });    
            }
        }).catch(e => {
            setLoading(false);
            notify('user.changePassFail', { type: 'warning' });
        })
    };

    return (
        <Box
                sx={{
                    background: 'url(/background.jpg)',
                    backgroundColor: '#50abd5',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
            >
        <SimpleForm onSubmit={submit} toolbar={false} validate={validateForm}>
            <Card>
               <Title title={'user.changePass'} />
                <CardContent>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.oldPass'} fullWidth source="password" />
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.newPass'} fullWidth source="newPassword" />
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }}>
                        <PasswordInput label={'user.repeatNewPass'} fullWidth source="repeatNewpassword" /> 
                    </Box>
                    <CardActions>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {loading && (
                                <CircularProgress
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('button.changePass')}
                        </Button>
                    </CardActions>
                </CardContent>
            </Card>
        </SimpleForm>
        </Box>
)};