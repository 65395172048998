import { useTranslate,
    Title,
    useListContext,
    ListBase,
    SimpleForm,
    FormDataConsumer,
    CheckboxGroupInput,
} from 'react-admin';
import { Box, Card, CardContent } from '@mui/material';

export const LiveView = () => {
    const translate = useTranslate();
    const access_token = localStorage.getItem('access_token');
    return <SimpleForm toolbar={null}>
        <Card sx={{ width: '100%' }}>
            <Title title={translate('resources.live.title')} />
            <CardContent>
                <Box 
                    display='flex'
                >
                    <Box sx={{ width: '100%' }}>
                        <FormDataConsumer>
                        {({ formData}) => {                    
                            return <LiveCams access_token={access_token} cams={formData.selectedCams}/>
                        }}
                        </FormDataConsumer>
                    </Box>
                    <Box >
                        <ListBase 
                            resource='cams'
                            perPage='100'
                        >
                            <Aside />
                        </ListBase>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    </SimpleForm>
       
};

const LiveCams = (props) => {
    //console.log("LiveCam props: "+JSON.stringify(props));
    if (props.cams) {
        if (props.cams.length <= 3) {
            return <Box display='flex' flexWrap="wrap">
                {props.cams.map((camId) => {
                    var url = `https://vmon-sub1.infometrika.ru/api/v1/cams/${camId}/live?t=${props.access_token}`;
                    return <Box key={camId} mr='0.2em' flex='1' >
                        <img style={{ height: 'auto', maxWidth: '100%' }} src={url} />
                    </Box>
                })}        
            </Box>
        } else {
            // ряды по 3 камеры
            var rv = [];
            var i;
            for (i = 0; i < props.cams.length; i=i+3) {
                var subdomain = Math.floor(i/6)+1;
                rv.push(<Box display='flex' key={i}>
                    {props.cams[i] ? <Box mr='0.2em' flex={1} key={props.cams[i]}>
                        <img 
                            style={{ height: 'auto', maxWidth: '100%' }} 
                            src={`https://vmon-sub${subdomain}.infometrika.ru/api/v1/cams/${props.cams[i]}/live?t=${props.access_token}`} />
                    </Box> : null}
                    {props.cams[i+1] ? <Box mr='0.2em' flex={1} key={props.cams[i+1]}>
                        <img 
                            style={{ height: 'auto', maxWidth: '100%' }} 
                            src={`https://vmon-sub${subdomain}.infometrika.ru/api/v1/cams/${props.cams[i+1]}/live?t=${props.access_token}`} />
                    </Box> : <Box mr='0.2em' width="33%" key={i+1} />}
                    {props.cams[i+2] ? <Box mr='0.2em' flex={1} key={props.cams[i+2]}>
                        <img 
                            style={{ height: 'auto', maxWidth: '100%' }} 
                            src={`https://vmon-sub${subdomain}.infometrika.ru/api/v1/cams/${props.cams[i+2]}/live?t=${props.access_token}`} />
                    </Box> : <Box mr='0.2em' width="33%" key={i+2} />}
                </Box>);
              }
            console.log(rv.length);
            return <Box display='flex' flexDirection="column">
                {rv}
            </Box>;
        }
    }
    else return null;
};

const Aside = () => {
    const { data, isLoading } = useListContext();
    if (isLoading) return null;
    /*var camsArr = [];
    data.map((record ) => {
        console.log("pushing:"+JSON.stringify(data[record.id]));
        camsArr.push(data[record.id])
    });*/
    //console.log("Aside data:"+JSON.stringify(data));
    return (
        <CheckboxGroupInput label="liveView.selectCams" source="selectedCams" row={false} choices={data}/>
    );
};