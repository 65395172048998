import { List,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    SelectField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    SelectInput,
    TextInput,
    Show,
    SimpleShowLayout,
    useRecordContext,
    TopToolbar,
} from 'react-admin';
import { useMediaQuery} from '@mui/material';
import { JsonField } from "react-admin-json-view";
import { BackButton } from './components/Buttons'
import { Pagination500 } from './components/Pagination'

export const WEventTypeChoices = [
    { id: 1, name: 'weventtypes.id1' },
    { id: 2, name: 'weventtypes.id2' },
    { id: 3, name: 'weventtypes.id3' },
    { id: 4, name: 'weventtypes.id4' },
    { id: 5, name: 'weventtypes.id5' },
    { id: 6, name: 'weventtypes.id6' },
    { id: 7, name: 'weventtypes.id7' },
    { id: 8, name: 'weventtypes.id8' },
    { id: 9, name: 'weventtypes.id9' },
    { id: 10, name: 'weventtypes.id10' },
    { id: 11, name: 'weventtypes.id11' },
    { id: 12, name: 'weventtypes.id12' },
    { id: 13, name: 'weventtypes.id13' },
    { id: 14, name: 'weventtypes.id14' },
    { id: 15, name: 'weventtypes.id15' },
    { id: 16, name: 'weventtypes.id16' },
    { id: 17, name: 'weventtypes.id17' },
    { id: 18, name: 'weventtypes.id18' },
    { id: 19, name: 'weventtypes.id19' },
    { id: 20, name: 'weventtypes.id20' },
    { id: 21, name: 'weventtypes.id21' },
    { id: 22, name: 'weventtypes.id22' },
    { id: 23, name: 'weventtypes.id23' },
    { id: 24, name: 'weventtypes.id24' },
    { id: 25, name: 'weventtypes.id25' },
    { id: 26, name: 'weventtypes.id26' },
    { id: 27, name: 'weventtypes.id27' },
    { id: 28, name: 'weventtypes.id28' },
    { id: 29, name: 'weventtypes.id29' },
    { id: 30, name: 'weventtypes.id30' },
    { id: 31, name: 'weventtypes.id31' },
    { id: 32, name: 'weventtypes.id32' },
    { id: 33, name: 'weventtypes.id33' },
    { id: 34, name: 'weventtypes.id34' },
    { id: 35, name: 'weventtypes.id35' },
    { id: 36, name: 'weventtypes.id36' },
    { id: 37, name: 'weventtypes.id37' },
    { id: 38, name: 'weventtypes.id38' },
    { id: 39, name: 'weventtypes.id39' },
    { id: 40, name: 'weventtypes.id40' },
];

export const ObjectTypeChoices = [
    { id: 1, name: 'WObjectTypeUser' },
    { id: 2, name: 'WObjectTypeGroup' },
    { id: 3, name: 'WObjectTypeCustomer' },
    { id: 4, name: 'WObjectTypeVEventType' },
    { id: 5, name: 'WObjectTypeVCam' },
    { id: 6, name: 'WObjectTypeVCommunicationSetting' },
    { id: 7, name: 'WObjectTypeVFile' },
    { id: 8, name: 'WObjectTypeVDetectionSetting' },
    { id: 1000, name: 'WObjectTypeContentProvider' },
    { id: 1001, name: 'WObjectTypeSmsCenter' },
    { id: 1002, name: 'WObjectTypeHandset' },
    { id: 1003, name: 'WObjectTypeSettings' },
];

export const WEventList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <SelectField source="type" choices={WEventTypeChoices} sortable={false} basePath="wevents" record={record}/>}
                    secondaryText={record => `${translate('resources.wevents.fields.created_at')}: ${record.created_at}`}
                    tertiaryText={record => <ReferenceField source="w_user_id" reference="users" link={false} basePath="users" record={record}>
                        <TextField source="login" />
                    </ReferenceField>}
                />
            ) : (   
            <Datagrid bulkActionButtons={false} rowClick="show"> 
                <SelectField source="type" choices={WEventTypeChoices} sortable={false}/>
                <DateField source="created_at" locales="ru-RU" showTime />
                <ReferenceField source="w_user_id" reference="users" link={false}>
                    <TextField source="login" />
                </ReferenceField>      
                <ReferenceField source="w_session_id" reference="sessions" link={false} sortable={false}>
                    <TextField source="id" />
                </ReferenceField>               
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <SelectInput source="type" choices={WEventTypeChoices} />,
    <ReferenceInput source="w_user_id" reference="users" filterToQuery={searchText => ({ login: searchText })} >
        <SelectInput optionText="login" />
    </ReferenceInput>,
    <TextInput source="w_session_id" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
];

export const WEventShow = () => {
    return <Show 
        title={<Title />}
        actions={<Buttons />}>
        <SimpleShowLayout >
            <TextField source="id" />
            <SelectField source="type" choices={WEventTypeChoices}/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <ReferenceField source="w_user_id" reference="users" link={false}>
                <TextField source="login" />
            </ReferenceField>      
            <ReferenceField source="w_session_id" reference="sessions" link={false} sortable={false}>
                <TextField source="id" />
            </ReferenceField>
            <SelectField source="object_type" choices={ObjectTypeChoices}/>
            <TextField source="object_ids" />
            <JsonField
                label={'resources.wevents.data'}
                source="details"
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: false,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleShowLayout>
    </Show>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.wevents.title")}{record ? `${record.id}` : ''}</span>;
};

const Buttons = () => (
    <TopToolbar>
        <BackButton/>
    </TopToolbar>
);