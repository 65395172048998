import { Box } from '@mui/material';
import { useTranslate } from 'react-admin';
import queryString from 'query-string'
import { useLocation } from "react-router-dom";

export const ShowFrame = () => {
    const { search } = useLocation()
    const values = queryString.parse(search)
    const translate = useTranslate();
    return <Box>
        <img 
            style={{ height: 'auto', maxWidth: '100%' }} 
            src={`/api/v1/cams/${values.v_cam_id}/frame?ts=${values.ts}&t=${values.t}`} 
            alt={translate('resources.vevents.frameError')}
        />
    </Box>
}