export const ViewUsersGroups = 1;
export const EditUsersGroups = 2;
export const ViewCamSettings = 3;
export const EditCamSettings = 4;
export const ViewCommSettings = 5;
export const EditCommSettings = 6;
export const ViewCommunications = 7;
export const ViewVEvents = 8;
export const ViewInteractions = 9;
export const EditVEvents = 10;
export const ViewCamsLive = 11;
export const ViewCamsArchive = 12;
export const EditCustomers = 13;

export function check(userRights, right) {
    if (userRights){
        for (let i = 0; i < userRights.length ; i++) {
            if (userRights[i] == right) {
            return true;
            }
        }
    }
    return false;
};