import { useState } from 'react';
import Box from '@mui/material/Box';
import {
    usePermissions,
    useTranslate,
    MenuItemLink,
    useSidebarState,
} from 'react-admin';
import TuneIcon from '@mui/icons-material/Tune';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import SubMenu from './SubMenu';
import * as Rights from './checkRights';
import ViewListIcon from '@mui/icons-material/ViewList';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DescriptionIcon from '@mui/icons-material/Description';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ForumIcon from '@mui/icons-material/Forum';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';

export const MyMenu = ({ dense = false }) => {
    const [state, setState] = useState({
        menuVideo: true,
        menuReports: true,
        menuSettings: true,
    });
    const translate = useTranslate();
    const [open] = useSidebarState();
    const { permissions } = usePermissions();

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 300 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {(Rights.check(permissions,Rights.ViewCamsLive) 
                || Rights.check(permissions,Rights.ViewCamsArchive))
                 && <SubMenu
                handleToggle={() => handleToggle('menuVideo')}
                isOpen={state.menuVideo}
                name="menu.menuVideo"
                icon={<PlayArrowIcon />}
                dense={dense}
            >
                {(Rights.check(permissions,Rights.ViewCamsLive))
                    && <MenuItemLink
                    to="/live"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.live.menu')}
                    leftIcon={<PlayArrowIcon />}
                    dense={dense}
                />}
            </SubMenu>}
            {(Rights.check(permissions,Rights.ViewInteractions) 
                || Rights.check(permissions,Rights.ViewCommunications)
                || Rights.check(permissions,Rights.ViewVEvents)
                || Rights.check(permissions,Rights.EditVEvents))
                 && <SubMenu
                handleToggle={() => handleToggle('menuReports')}
                isOpen={state.menuReports}
                name="menu.menuReports"
                icon={<DescriptionIcon />}
                dense={dense}
            >
                {(Rights.check(permissions,Rights.ViewCommunications))
                    && <MenuItemLink
                    to="/communications"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.communications.menu')}
                    leftIcon={<DescriptionIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewVEvents))
                    && <MenuItemLink
                    to="/vevents"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.vevents.menu')}
                    leftIcon={<VideoLibraryIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewInteractions))
                    && <MenuItemLink
                    to="/interactions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.interactions.menu')}
                    leftIcon={<ForumIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.EditVEvents))
                    && <MenuItemLink
                    to="/vevents/create"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.vevents.menuCreate')}
                    leftIcon={<VideoCallIcon />}
                    dense={dense}
                />}
            </SubMenu>}
            {(Rights.check(permissions,Rights.ViewUsersGroups) 
                || Rights.check(permissions,Rights.ViewCamSettings) 
                || Rights.check(permissions,Rights.ViewCommSettings))
                 && <SubMenu
                handleToggle={() => handleToggle('menuSettings')}
                isOpen={state.menuSettings}
                name="menu.menuSettings"
                icon={<TuneIcon />}
                dense={dense}
            >
                {(Rights.check(permissions,Rights.ViewCommSettings))
                && <MenuItemLink
                    to="/detection_settings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.detection_settings.menu')}
                    leftIcon={<VideoCameraBackIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewCommSettings))
                && <MenuItemLink
                    to="/communication_settings"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.communication_settings.menu')}
                    leftIcon={<NotificationsActiveIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewCamSettings))
                && <MenuItemLink
                    to="/cams"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.cams.menu')}
                    leftIcon={<VideocamIcon />}
                    dense={dense}
                />}
                {(Rights.check(permissions,Rights.ViewCommSettings))
                && <MenuItemLink
                    to="/event_types"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.event_types.menu')}
                    leftIcon={<CollectionsIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.users.menu')}
                    leftIcon={<PersonIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/groups"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.groups.menu')}
                    leftIcon={<GroupIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to="/sessions"
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.sessions.menu')}
                    leftIcon={<ViewListIcon />}
                    dense={dense}
                />}
                {Rights.check(permissions,Rights.ViewUsersGroups) && <MenuItemLink
                    to={`/wevents`}
                    state={{ _scrollToTop: true }}
                    primaryText={translate('resources.wevents.menu')}
                    leftIcon={<EventNoteIcon />}
                    dense={dense}
            /> }
            </SubMenu>}
        </Box>
    );
};