import { List,
    ReferenceInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    SelectField,
    ReferenceField,
    DateTimeInput,
    useTranslate,
    BooleanField,
    SelectInput,
    BooleanInput,
    Show,
    Link,
    SimpleShowLayout,
    ArrayField,
    Create,
    SimpleForm,
    useRecordContext,
    required,
    usePermissions,
    TopToolbar,
    Labeled,
} from 'react-admin';
import * as Rights from './checkRights';
import { Box, Typography, useMediaQuery } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TriggerTypeChoices } from './Constants'
import { BackButton } from './components/Buttons'
import { Pagination500 } from './components/Pagination'
import { JsonField } from "react-admin-json-view";

export const VEventList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const translate = useTranslate();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={false}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => <ReferenceField source="v_event_type_id" reference="event_types" link={false} 
                        basePath="vevents" record={record}>
                        <TextField source="name" />
                    </ReferenceField>}
                    secondaryText={record => `${translate('resources.interactions.fields.created_at')}: ${record.created_at}`}
                    tertiaryText={record => <ReferenceField source="v_cam_id" reference="cams" link={false} sortable={false}
                        basePath="vevents" record={record}>
                        <TextField source="name" />
                    </ReferenceField>}
                    linkType="show"
                />
            ) : (
            <Datagrid bulkActionButtons={false} rowClick={'show'}> 
                <ReferenceField source="v_event_type_id" reference="event_types" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
                <BooleanField source="is_finished" />
                <DurationField source="duration" />
                <ReferenceField source="v_cam_id" reference="cams" link={false} sortable={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label={'resources.vevents.fields.folder'} source="v_cam_id" reference="cams" link={false} sortable={false}>
                    <TextField source="folder" />
                </ReferenceField>
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <ReferenceInput source="v_event_type_id" reference="event_types">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <BooleanInput source="is_finished" />,
    <ReferenceInput source="v_cam_id" reference="cams">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

/* global BigInt */

const DurationField = (props) => {
    const record = useRecordContext();
    if(!record.duration) {return null}
    var sec_num = Number(BigInt(record.duration)/BigInt(1000000000));
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    let value = hours+':'+minutes+':'+seconds;
    const { className, emptyText, ...rest } = props;
    return <Typography
        component="span"
        variant="body2"
        className={className}
        {...rest}
    >
        {value != null && typeof value !== 'string'
            ? JSON.stringify(value)
            : value || emptyText}
    </Typography>
};
//------------
export const VEventCreate = () => {
    return <Create redirect='list'>
        <SimpleForm validate={validateCreate}>
            <ReferenceInput source="v_event_type_id" reference="event_types">
                <SelectInput optionText="name" validate={[required()]}/>
            </ReferenceInput>
            <ReferenceInput source="v_cam_id" reference="cams">
                <SelectInput optionText="name" validate={[required()]}/>
            </ReferenceInput>
            <DateTimeInput label={'resources.vevents.startTime'} source="start" />
            <DateTimeInput label={'resources.vevents.endTime'} source="end" />
        </SimpleForm>
    </Create>
};

const validateCreate = (values) => {
    const errors = {};
    if (!values.v_event_type_id) {
        errors.v_event_type_id = 'resources.vevents.eventTypeError';
    }
    if (!values.v_cam_id) {
        errors.v_cam_id = 'resources.vevents.vCamError';
    } 
    if (!values.start) {
        errors.start = 'resources.vevents.startError';
    }
    if (!values.end) {
        errors.end = 'resources.vevents.endError';
    }
    var start = new Date(values.start);
    var end = new Date(values.end);
    if(end <= start) {
        errors.end = 'resources.vevents.endEarlier';
    }
    return errors
};
//------------
export const VEventShow = () => {
    return <Show actions={<Buttons />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="v_event_type_id" reference="event_types" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <DateField source="created_at" locales="ru-RU" showTime />
            <BooleanField source="is_finished" />
            <DurationField source="duration" />
            <ReferenceField source="v_cam_id" reference="cams" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <VEventTriggers/>
        </SimpleShowLayout>
    </Show>
};

const Buttons = () => (
    <TopToolbar>
        <BackButton/>
    </TopToolbar>
);

const VEventTriggers = () => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    let record = useRecordContext();
    record.sorted_v_event_triggers = record.v_event_triggers?.sort(function compareFn(a, b) {
        let ad = Date.parse(a.start_at);
        let bd = Date.parse(b.start_at)
        if (ad < bd) {
            //console.log("ad: ", ad, "bd: ", bd, "return -1");
          return -1;
        }
        if (ad > bd) {
            //console.log("ad: ", ad, "bd: ", bd, "return 1");
          return 1;
        }
        // a must be equal to b
        //console.log("ad: ", ad, "bd: ", bd, "return 0");
        return 0;
      });
    //console.log("v_event_triggers: ", record.v_event_triggers)
    //console.log("sorted_v_event_triggers: ", record.sorted_v_event_triggers)
    return <Box 
            p='1em 1em 1em 1em' 
            border={1} borderColor="gray"
            style={{ width: 800 }}>
        <Typography variant="h6" >
            {translate('resources.vevents.fields.v_event_triggers')}
        </Typography>
        <ArrayField source="sorted_v_event_triggers" style={{ width: 800 }}>
            <Datagrid
                isRowExpandable={row => {
                    return row.v_file_id}}
                expand={<FramePanel/>}
                rowClick="expand"
                bulkActionButtons={false}> 
                <SelectField label={'resources.veventtriggers.fields.type'} source="type" choices={TriggerTypeChoices}/>
                <DateField label={'resources.veventtriggers.fields.start_at'} source="start_at" locales="ru-RU" showTime />
                {Rights.check(permissions,Rights.ViewCamsArchive) ? 
                    <RequestFrameLink v_cam_id={record.v_cam_id}/>
                    : null }
            </Datagrid>
        </ArrayField>
        </Box>
}

const FramePanel = () => {
    //console.log('props: '+JSON.stringify(props));
    const access_token = localStorage.getItem('access_token');
    const translate = useTranslate();
    const record = useRecordContext();
    // если у триггера есть fileid то вставляем картинку, если нету - пишем ошибку
    return <Box>
    <Box>
        {record.v_file_id ? <img 
            style={{ height: 'auto', maxWidth: '100%' }} 
            //src={`/api/v1/cams/${props.v_cam_id}/frame?ts=${props.record.start_at}&t=${access_token}`} 
            src={`/api/v1/files/${record.v_file_id}/download?t=${access_token}`} 
            alt={translate('resources.vevents.frameError')}
            /> : <Typography>
                    {translate('resources.vevents.frameError')}
                </Typography>}
                </Box>
                <Box><Labeled><JsonField
            label={translate('resources.veventtriggers.fields.predictions')}
            source="predictions"
            jsonString={false} // Set to true if the value is a string, default: false
            reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
            }}
        /></Labeled></Box>
        </Box>
};

const RequestFrameLink = (props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    //console.log('v_cam_id: '+JSON.stringify(v_cam_id));
    const access_token = localStorage.getItem('access_token');
    return (record.v_file_id) ?
        <Link to={`/frame?v_cam_id=${props.v_cam_id}&ts=${record.start_at}&t=${access_token}`} target="_blank" rel="noopener noreferrer">
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <VisibilityIcon />
                {translate('button.requestFrame')}
            </div>
        </Link>
        : null
};







