import { List, Datagrid, TextField, DateField, SimpleList, TextInput, DateTimeInput, BulkDeleteButton, required,
    Edit, SimpleForm, TopToolbar, Labeled, Create, Show, Button, SimpleShowLayout, ReferenceField, SelectField, AutocompleteInput, ReferenceInput, SelectInput, 
    PasswordInput, email, BooleanInput, BooleanField, FunctionField, FormDataConsumer, useNotify } from 'react-admin';
import * as Rights from './checkRights';
import { usePermissions, useTranslate, useRedirect, useRecordContext, useResourceContext } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import ViewListIcon from '@mui/icons-material/ViewList';
import { StatusChoices, UserStatusChoices } from './Constants'
import { BackButton } from './components/Buttons'
import { Pagination500 } from './components/Pagination'

export const UserList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.login}
                    secondaryText={record => <ReferenceField source="w_group_id" reference="groups" link={false} basePath="groups" record={record}>
                        <TextField source="name" />
                    </ReferenceField>}
                    tertiaryText={record => <SelectField source="status" choices={UserStatusChoices} basePath="groups" record={record}/>}
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditUsersGroups) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditUsersGroups) ? "edit" : "show"}>
                <TextField source="login" />
                <SelectField source="status" choices={UserStatusChoices}/>
                <ReferenceField source="w_group_id" reference="groups" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />
                <TextField source="msisdn" sortable={false} />
                <TextField source="email" sortable={false} />
            </Datagrid> )}
        </List>
    )
};

const customFilter = [
    <TextInput source="login" />,
    <TextInput source="msisdn" />,
    <TextInput source="email" />,
    <AutocompleteInput source="status" choices={UserStatusChoices} />,
    <ReferenceInput source="w_group_id" reference="groups">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];

const localeChoices = [
    { id: 'ru', name: 'RU' },
    { id: 'en', name: 'EN' },
];

const DefaultBulkActionButtons = props => {
    // если выбран дефолт админ, скрываем кнопку удалить - его нельзя удалять
    if (props.selectedIds.includes(1)) {
        return <></>    
    } else {
        return <>
            <BulkDeleteButton {...props} />
        </>
    }    
};

export const UserEdit = () => {
    const notify = useNotify();
    const onError = (error) => {
        // отдельно обрабатываем код возврата что логин уже занят
        if (error.status === 600) {
            notify('resources.users.loginExistsError', { type: 'warning' });
        } else { // иначе - стандартная обработка ошибки
            notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
        }
    };

    return <Edit title={<Title />} actions={<Actions/>} mutationOptions={{ onError }} mutationMode='pessimistic'>
        <SimpleForm validate={validateEdit}>
            <TextInput disabled source="id" />
            <TextInput source="login" />
            <FormDataConsumer>
                {({ formData }) => {
                    let disableFields = (formData.status === 4); //NotActivated
                    return <>
                        {disableFields ? <Labeled><FunctionField label="resources.users.fields.activation_link" render={(record) => {
                            if (record.activation_token) {
                                let host = window.location.protocol + "/" + window.location.host + "/#/activate?login=";
                                return `${host}${record.login}&token=${record.activation_token}`
                            } else {
                                return "-"
                            }
                            }} /></Labeled> : null}
                        <SelectInput disabled={disableFields} source="status" choices={(disableFields) ? UserStatusChoices : StatusChoices} validate={[required()]}/>
                        <PasswordInput disabled={disableFields} source="password" />
                        </>
                }}
            </FormDataConsumer>
            <TextInput source="msisdn" />
            <TextInput source="email" type="email" validate={[email()]}/>
            <TextInput source="first_name" />
            <TextInput source="middle_name" />
            <TextInput source="last_name" />
            <ReferenceInput source="w_group_id" reference="groups">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="locale" choices={localeChoices} />
            <BooleanInput source="is_password_reset_required" />
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    return <span>{translate("resources.users.title")}{record ? `${record.login}` : ''}</span>;
};

const Actions = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const record = useRecordContext();
    const resource = useResourceContext();
    const { permissions } = usePermissions();
    return <TopToolbar>
        <BackButton/>
        {Rights.check(permissions,Rights.ViewUsersGroups) ? 
            <Button color="primary"
                label={translate('button.showHistory')}
                onClick={() => redirect(`/history/${resource}/${record.id}`)} >
                <HistoryIcon />
            </Button> : 
            false }
        {Rights.check(permissions,Rights.ViewUsersGroups) ? 
            <Button color="primary"
                label={translate('button.showSessions')}
                onClick={() => redirect(`/sessions?filter={"w_user_id":${record.id}}`)} >
                <ViewListIcon />
            </Button> : 
            false }
    </TopToolbar>
};

const validateEdit = (values) => {
    const errors = {};
    if (!values.login) {
        errors.login = 'resources.users.loginError';
    }
    if (!values.status) {
        errors.status = 'resources.users.statusMissing';
    }
    if (values.id === 1 && values.status !== 1) {
        errors.status = 'resources.users.id1StatusError';
    }
    if (values.password) {
        let strPass = String(values.password);
        if (strPass.length < 6 ) {
            errors.password = 'resources.users.passShort';
        }
    }
    if (values.msisdn) {
        let isnum = /^[1-9]\d{10,14}$/.test(values.msisdn);
        if (!isnum) {
            errors.msisdn = 'user.msisdnError';
        }
    }
    if (!values.w_group_id) {
        errors.w_group_id = 'resources.users.groupError';
    }
    if (values.id === 1 && values.w_group_id !== 1) {
        errors.w_group_id = 'resources.users.id1Error';
    }
    if (!values.locale) {
        errors.locale = 'resources.users.localeError';
    }
    return errors
};

export const UserCreate = () => {
    const notify = useNotify();
    const onError = (error) => {
        // отдельно обрабатываем код возврата что логин уже занят
        if (error.status === 600) {
            notify('resources.users.loginExistsError', { type: 'warning' });
        } else { // иначе - стандартная обработка ошибки
            notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', { type: 'warning' });
        }
    };

    return <Create mutationOptions={{ onError }} redirect='show'>
        <SimpleForm validate={validateCreate}>
            <TextInput source="login" />
            <TextInput source="msisdn" />
            <TextInput source="email" type="email" validate={[email()]}/>
            <TextInput source="first_name" />
            <TextInput source="middle_name" />
            <TextInput source="last_name" />
            <ReferenceInput source="w_group_id" reference="groups">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="locale" choices={localeChoices} />
        </SimpleForm>
    </Create>
};

const validateCreate = (values) => {
    const errors = {};
    if (!values.login) {
        errors.login = 'resources.users.loginError';
    }
    /*if (!values.password) {
        errors.password = 'resources.users.passMissing';
    } else {
        let strPass = String(values.password);
        if (strPass.length < 6 ) {
            errors.password = 'resources.users.passShort';
        }
    }*/
    if (values.msisdn) {
        let isnum = /^[1-9]\d{10,14}$/.test(values.msisdn);
        if (!isnum) {
            errors.msisdn = 'user.msisdnError';
        }
    }
    if (!values.w_group_id) {
        errors.w_group_id = 'resources.users.groupError';
    }
    if (!values.locale) {
        errors.locale = 'resources.users.localeError';
    }
    return errors
};

export const UserShow = () => {
    return <Show title={<Title />} actions={<Actions/>}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="login" />
            <ActivationLinkField/>
            <SelectField source="status" choices={UserStatusChoices}/>
            <TextField source="msisdn" />
            <TextField source="email" />
            <TextField source="first_name" />
            <TextField source="middle_name" />
            <TextField source="last_name" />
            <ReferenceField source="w_group_id" reference="groups" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <SelectField source="locale" choices={localeChoices}/>
            <BooleanField source="is_password_reset_required" />
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

const ActivationLinkField = () => {
    const record = useRecordContext();
    if (record.status === 4) {
        return <Labeled><FunctionField label="resources.users.fields.activation_link" render={(record) => {
        if (record.activation_token) {
            let host = window.location.protocol + "/" + window.location.host + "/#/activate?login=";
            return `${host}${record.login}&token=${record.activation_token}`
        } else {
            return "-"
        }
        }} /></Labeled>
    }
    return null;
};