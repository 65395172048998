import HistoryIcon from '@mui/icons-material/History';
import { usePermissions, useTranslate, useRedirect, useRecordContext, useResourceContext, TopToolbar, Button } from 'react-admin';
import * as Rights from '../checkRights';
import { BackButton } from './Buttons'

export const HistoryButtonToolbar = (props) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const record = useRecordContext()
    const resource = useResourceContext();
    const { permissions } = usePermissions();
    return <TopToolbar>
        <BackButton/>
        {Rights.check(permissions,props.checkRight) ? 
            <Button color="primary"
                label={translate('button.showHistory')}
                onClick={() => redirect(`/history/${resource}/${record.id}`)} >
                <HistoryIcon />
            </Button> : 
            false }
    </TopToolbar>
};

export const BackButtonToolbar = () => {
    return <TopToolbar>
        <BackButton/>
    </TopToolbar>
};