import { List,
    AutocompleteInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    Edit, 
    Create,
    Show,
    SimpleForm,
    SelectField,
    BulkDeleteButton,
    TextInput,
    DateTimeInput,
    useTranslate,
    NumberInput,
    usePermissions,
    SimpleShowLayout,  
    SelectInput,  
    ReferenceInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    FormDataConsumer,
    ReferenceField,
    NumberField,
    ReferenceArrayField,
    SingleFieldList,
    useRecordContext,
    useResourceContext,
    Labeled,
    required,
    minValue,
    maxValue,
} from 'react-admin';
import * as Rights from './checkRights';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { CommSettTypeChoices, StatusChoices, WeekdayChoices, MonthChoices, ReportFreqChoices, InteractionTypeChoices, ReportTypeChoices } from './Constants'
import { HistoryButtonToolbar } from './components/Toolbars'
import { Pagination500 } from './components/Pagination'

export const CommSettList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => <SelectField source="type" choices={CommSettTypeChoices} basePath="communication_settings" record={record}/>}
                    tertiaryText={record => <SelectField source="status" choices={StatusChoices} basePath="communication_settings" record={record}/>}
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditCommSettings) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditCommSettings) ? "edit" : "show"}>
                <TextField source="name" />
                <SelectField source="status" choices={StatusChoices}/>
                <SelectField source="type" choices={CommSettTypeChoices}/>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />                
            </Datagrid> )}
        </List>
    )
};

const DefaultBulkActionButtons = props => (
    <>
        <BulkDeleteButton {...props} />
    </>
);

const customFilter = [
    <TextInput source="name" />,
    <AutocompleteInput source="status" choices={StatusChoices} />,
    <AutocompleteInput source="type" choices={CommSettTypeChoices} />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];
//------------
export const CommSettEdit = () => {
    const translate = useTranslate();
    return <Edit title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>} mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput disabled source="type" choices={CommSettTypeChoices} validate={[required('resources.communication_settings.typeRequired')]}/>
            <TextInput source="name" validate={[required('resources.communication_settings.nameRequired')]}/>
            <AutocompleteInput source="status" choices={StatusChoices} />
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
            <FormDataConsumer>
                {({ formData }) => {
                    return getEditElements(translate, formData)
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
};

const Title = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    if (!record) {
        return '';
    }
    if (record.type === 1) {
        return <span>{translate(`resources.${resource}.titleNotif`)}{record.name}</span>;
    };
    if (record.type === 2) {
        return <span>{translate(`resources.${resource}.titleReport`)}{record.name}</span>;
    };
};

const getEditElements = (translate, formData) => {
    if(formData.type === 1){
        //notif edit
        return <Box 
                p='1em 1em 1em 1em' 
                style={{ width: 300 }} 
                border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.communication_settings.sectionNotif')}
            </Typography>
            <ReferenceInput style={{ width: 300 }} 
                source="data.notification_setting.v_event_type_id" 
                reference="event_types">
                <SelectInput optionText="name" label="resources.communication_settings.notifEdit1" />
            </ReferenceInput>
            <NumberInput style={{ width: 300 }} label="resources.communication_settings.notifEdit2" source="data.notification_setting.lasts" />
			<ReferenceArrayInput 
                style={{ width: 300 }} 
                source="data.notification_setting.v_cam_id" 
                reference="cams">
                <CheckboxGroupInput optionText="name" label="resources.communication_settings.notifEdit3" />
            </ReferenceArrayInput>
            <Box display="flex">
                    <Box flex={1} mr={'1em'}>
                        <NumberInput fullWidth source="data.notification_setting.hours_from" label="resources.communication_settings.notifEdit3_1" validate={[minValue(0), maxValue(24)]}/>
                    </Box>
                    <Box flex={1} mr={'1em'}>
                        <NumberInput fullWidth source="data.notification_setting.hours_to" label="resources.communication_settings.notifEdit3_2" validate={[minValue(0), maxValue(24)]}/>
                    </Box>
            </Box>
            <SelectInput style={{ width: 300 }} label="resources.communication_settings.notifEdit4" source="data.notification_setting.interaction_type" choices={InteractionTypeChoices} validate={[required()]}/>
            <InteractionTypeDependantElements formData={formData}/>
        </Box>
    }
    if(formData.type === 2){
        //report edit
        return <Box 
                p='1em 1em 1em 1em' 
                style={{ width: 300 }} 
                border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.communication_settings.sectionReport')}
            </Typography>
            <SelectInput 
                disabled
                defaultValue ={1}
                style={{ width: 300 }} 
                label="resources.communication_settings.reportEdit1"
                source="data.report_setting.v_report_type_id" 
                choices={ReportTypeChoices}
                validate={[required()]}
                />
            <SelectInput 
                style={{ width: 300 }} 
                label="resources.communication_settings.reportEdit2"
                source="data.report_setting.frequency" 
                choices={ReportFreqChoices}
                validate={[required()]}
                />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    //console.log('formdata: '+JSON.stringify(formData));
                    if(!formData.data) {return}
                    if (formData.data.report_setting.frequency === 1) { //daily
                        return <div>
                        <Box display={{ md: 'flex', lg: 'flex' }} >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                                <Typography >
                                    {translate('resources.communication_settings.reportEdit3')}
                                </Typography>
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" />
                            </Box>
                            <Box flex={1} >
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" />
                            </Box>
                        </Box>
                        </div>
                    }
                    if (formData.data.report_setting.frequency === 2) { //weekly
                        return <div>
                        <Box display={{ md: 'flex', lg: 'flex' }} >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                                <Typography >
                                    {translate('resources.communication_settings.reportEdit3')}
                                </Typography>    
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                                <SelectInput 
                                    style={{ width: 150 }} 
                                    label="resources.communication_settings.reportEdit6"
                                    source="data.report_setting.day" 
                                    choices={WeekdayChoices}
                                    validate={[required()]}
                                    />
                            </Box>
                        </Box>
                        <Box display={{ md: 'flex', lg: 'flex' }}>
                            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }} >    
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" />
                            </Box>
                            <Box flex={1} >
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" />
                            </Box>
                        </Box>
                        </div>
                    }
                    if (formData.data.report_setting.frequency === 3) { //monthly
                        return <div>
                        <Box display={{ md: 'flex', lg: 'flex' }} >
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                                <Typography >
                                    {translate('resources.communication_settings.reportEdit3')}
                                </Typography>    
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                                <SelectInput 
                                    style={{ width: 40 }}
                                    optionText="id"
                                    label="resources.communication_settings.reportEdit7"
                                    source="data.report_setting.day" 
                                    choices={MonthChoices}
                                    translateChoice={false}
                                    validate={[required()]}
                                    />
                            </Box>
                        </Box>
                        <Box display={{ md: 'flex', lg: 'flex' }}>
                            <Box flex={2} mr={{ xs: 0, sm: '0.5em' }} >    
                            </Box>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" />
                            </Box>
                            <Box flex={1} >
                                <NumberInput style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" />
                            </Box>
                        </Box>
                        </div>
                    }
                }}
            </FormDataConsumer>
            <ReferenceArrayInput 
                style={{ width: 300 }} 
                source="data.report_setting.v_cam_id" 
                reference="cams">
                <CheckboxGroupInput label="resources.communication_settings.reportEdit8" optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput 
                style={{ width: 300 }} 
                source="data.report_setting.w_user_id" 
                reference="users"
                perPage={100}>
                <CheckboxGroupInput label="resources.communication_settings.reportEdit9" optionText="login" />
            </ReferenceArrayInput>
        </Box>
    }
};

const InteractionTypeDependantElements = (props) => {
    const { formData } = props
    //console.log('formdata inside func: '+JSON.stringify(formData));
    //console.log('ChannelDependantElements func: ', formData.hasOwnProperty('data'));
    if(formData.hasOwnProperty('data') && formData.data.hasOwnProperty('notification_setting') && (formData.data.notification_setting.interaction_type === 1 || formData.data.notification_setting.interaction_type === 2)){
        return <Box><TextInput 
            style={{ width: 300 }} 
            multiline 
            label="resources.communication_settings.notifEdit5" 
            source="data.notification_setting.content_template" />
        <ReferenceArrayInput 
            style={{ width: 300 }} 
            source="data.notification_setting.w_user_id" 
            reference="users"
            perPage={100}>
            <CheckboxGroupInput optionText="login" label="resources.communication_settings.notifEdit6" />
        </ReferenceArrayInput>
        </Box>
    }
    if(formData.hasOwnProperty('data') && formData.data.hasOwnProperty('notification_setting') && formData.data.notification_setting.interaction_type === 3){
        return <Box>
            <TextInput 
            style={{ width: 300 }} 
            multiline 
            label="resources.communication_settings.notifEdit5" 
            source="data.notification_setting.content_template" />
            <TextInput 
            style={{ width: 300 }} 
            label="resources.communication_settings.notifEdit7" 
            source="data.notification_setting.tg_group_id" />
            <TextInput 
            style={{ width: 300 }} 
            label="resources.communication_settings.notifEdit8" 
            source="data.notification_setting.tg_group_name" />
            <TextInput 
            style={{ width: 300 }} 
            label="resources.communication_settings.notifEdit9" 
            source="data.notification_setting.tg_bot_credentials" />
            <TextInput 
            style={{ width: 300 }} 
            label="resources.communication_settings.notifEdit10" 
            source="data.notification_setting.tg_bot_name" />
        </Box>
    }
    return null
}
//------------
export const CommSettCreate = () => {
    const translate = useTranslate();
    return <Create redirect='list'>
        <SimpleForm>
        <SelectInput source="type" choices={CommSettTypeChoices} validate={[required('resources.communication_settings.typeRequired')]}/>
        <TextInput source="name" validate={[required('resources.communication_settings.nameRequired')]}/>
        <FormDataConsumer>
            {({ formData }) => {
                //console.log('formdata: '+JSON.stringify(formData));
                return getEditElements(translate, formData)
            }}
        </FormDataConsumer>
    </SimpleForm>
    </Create>
};
//------------
export const CommSettShow = () => {
    return <Show title={<Title />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>}>
        <SimpleShowLayout>
            <TextField source="id" />
            <SelectField source="type" choices={CommSettTypeChoices}/>
            <TextField source="name" />
            <SelectField source="status" choices={StatusChoices}/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
            <CommSettElements/>
        </SimpleShowLayout>
    </Show>
};

const CommSettElements = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    //console.log(record);
    if(record.type === 1){
        //notif 
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
            <Typography variant="h6" >
                {translate('resources.communication_settings.sectionNotif')}
            </Typography>
            <ReferenceField style={{ width: 300 }} 
                linkType={false}
                source="data.notification_setting.v_event_type_id" 
                reference="event_types">
                <Labeled><TextField source="name" label="resources.communication_settings.notifEdit1" /></Labeled>
            </ReferenceField>,
            <Labeled><NumberField style={{ width: 300 }} label="resources.communication_settings.notifEdit2" source="data.notification_setting.lasts" /></Labeled>
			<ReferenceArrayField 
                style={{ width: 300 }} 
                source="data.notification_setting.v_cam_id" 
                reference="cams">
                <Labeled><SingleFieldList label="resources.communication_settings.notifEdit3" >
                    <TextField source="name" />
                </SingleFieldList></Labeled>
            </ReferenceArrayField>
            <Labeled><SelectField 
                style={{ width: 300 }} 
                label="resources.communication_settings.notifEdit4" 
                source="data.notification_setting.interaction_type" 
                choices={InteractionTypeChoices}/></Labeled>
            <Labeled><TextField 
                style={{ width: 300 }} 
                multiline="true"
                label="resources.communication_settings.notifEdit5" 
                source="data.notification_setting.content_template" /></Labeled>
            <ReferenceArrayField
                style={{ width: 300 }} 
                source="data.notification_setting.w_user_id" 
                reference="users"
                perPage={100} >
                <Labeled><SingleFieldList label="resources.communication_settings.notifEdit6" >
                    <TextField source="login" />
                </SingleFieldList></Labeled>
            </ReferenceArrayField>
        </Box>
    }
    if(record.type === 2){
        //report
        let freqDependants
        if (record.data.report_setting.frequency === 1) { //daily
            freqDependants = [
                <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                    <Typography >
                        {translate('resources.communication_settings.reportEdit3')}
                    </Typography>
                </Box>,
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >    
                    <NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" />
                </Box>,
                <Box flex={1} >
                    <NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" />
                </Box>
            ]
        }
        if (record.data.report_setting.frequency === 2) { //weekly
            freqDependants = [
                <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                    <Typography >
                        {translate('resources.communication_settings.reportEdit3')}
                    </Typography>    
                </Box>,
                <Labeled><SelectField 
                    style={{ width: 150 }} 
                    label="resources.communication_settings.reportEdit6"
                    source="data.report_setting.day" 
                    choices={WeekdayChoices}
                    /></Labeled>,
                <Labeled><NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" /></Labeled>,
                <Labeled><NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" /></Labeled>
            ]
        }
        if (record.data.report_setting.frequency === 3) { //monthly
            freqDependants = [
                <Box flex={1} mr={{ xs: 0, sm: '0.5em', lg: '1em' }} >
                    <Typography >
                        {translate('resources.communication_settings.reportEdit3')}
                    </Typography>    
                </Box>,
                <Labeled><SelectField 
                    style={{ width: 40 }}
                    optionText="id"
                    label="resources.communication_settings.reportEdit7"
                    source="data.report_setting.day" 
                    choices={MonthChoices}
                    translateChoice={false}
                    /></Labeled>,
                <Labeled><NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit4" source="data.report_setting.hour" /></Labeled>,
                <Labeled><NumberField style={{ width: 70 }} label="resources.communication_settings.reportEdit5" source="data.report_setting.minute" /></Labeled>
            ]
        }
        return <Box 
            p='1em 1em 1em 1em' 
            style={{ width: 300 }} 
            border={1} borderColor="gray">
                <Typography variant="h6" >
                    {translate('resources.communication_settings.sectionReport')}
                </Typography>
                <Labeled><SelectField 
                    style={{ width: 300 }} 
                    label="resources.communication_settings.reportEdit1"
                    source="data.report_setting.v_report_type_id" 
                    choices={ReportTypeChoices}/></Labeled>
                <Labeled><SelectField 
                    style={{ width: 300 }} 
                    label="resources.communication_settings.reportEdit2"
                    source="data.report_setting.frequency" 
                    choices={ReportFreqChoices}/></Labeled>
                {freqDependants}
            <ReferenceArrayField 
                style={{ width: 300 }} 
                source="data.report_setting.v_cam_id" 
                reference="cams">
                <Labeled><SingleFieldList label="resources.communication_settings.reportEdit8" >
                    <TextField source="name" />
                </SingleFieldList></Labeled>
            </ReferenceArrayField>
            <ReferenceArrayField 
                style={{ width: 300 }} 
                source="data.report_setting.w_user_id" 
                reference="users"
                perPage={100}>
                <Labeled><SingleFieldList label="resources.communication_settings.reportEdit9" >
                    <TextField source="login" />
                </SingleFieldList></Labeled>
            </ReferenceArrayField>
        </Box>
    }
}
