import { List,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    Edit, 
    Create,
    Show,
    SimpleForm,
    BulkDeleteButton,
    TextInput,
    DateTimeInput,
    usePermissions,
    SimpleShowLayout,
    Labeled,
    ArrayField,
    SingleFieldList,
    FunctionField,
    ChipField,
} from 'react-admin';
import * as Rights from './checkRights';
import { useMediaQuery } from '@mui/material';
import { HistoryButtonToolbar } from './components/Toolbars'
import { NamedTitle } from './components/Title'
import { TagsInput } from './components/TagsInput'
import { Pagination500 } from './components/Pagination'

export const EventTypeList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List 
            exporter={false}
            filters={customFilter}
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditCommSettings) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditCommSettings) ? "edit" : "show"}>
                <TextField source="name" />
                <ArrayField source="object_classes">
                    <SingleFieldList>
                        <FunctionField render={(record) => (
                        <ChipField record={{name: record }} source="name" />
                        )} />
                    </SingleFieldList>
                </ArrayField>
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />                
            </Datagrid> )}
        </List>
    )
};

const DefaultBulkActionButtons = props => (
    <>
        <BulkDeleteButton {...props} />
    </>
);

const customFilter = [
    <TextInput source="name" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];
//------------
export const EventTypeEdit = () => {
    return <Edit title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>} mutationMode='pessimistic'>
        <SimpleForm validate={validate}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TagsInput source="object_classes" />
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'resources.event_types.nameRequired';
    }    
    return errors
};
//------------
export const EventTypeCreate = () => {
    return <Create redirect='list'>
        <SimpleForm validate={validate}>
            <TextInput source="name" />
            <TagsInput source="object_classes" />
        </SimpleForm>
    </Create>
};
//------------
export const EventTypeShow = () => {
    return <Show title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCommSettings}/>}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};

