import * as React from "react"
import { useTranslate, List, Datagrid, TextField, 
    DateField,
    SelectField,
    ReferenceField,
    usePermissions } from 'react-admin'
import { WEventTypeChoices } from './wevents';
import { useParams } from "react-router-dom";
import { BackButtonToolbar } from './components/Toolbars'
import * as Rights from './checkRights';

export const History = () => {
    const { permissions } = usePermissions();
    let { resource, resource_id } = useParams();
    return <List 
        resource="wevents"
        actions={<BackButtonToolbar />}
        filter={{ 
            obj_type: resource,
            obj_id: resource_id,
        }}
        sort={{ field: 'created_at', order: 'DESC' }}
        exporter={false}
        title={<HistoryTitle/>}
        >
        <Datagrid 
            bulkActionButtons={false} 
            rowClick={Rights.check(permissions,Rights.ViewUsersGroups) ? "show" : null }>
            <SelectField source="type" choices={WEventTypeChoices} sortable={false}/>
            <DateField source="created_at" locales="ru-RU" showTime />
            <ReferenceField source="w_user_id" reference="users" link={false}>
               <TextField source="login" />
            </ReferenceField>
            <ReferenceField source="w_session_id" reference="sessions" link={false}>
                <TextField source="id" />
            </ReferenceField> 
        </Datagrid>
    </List>
};

const HistoryTitle = () => {
    const translate = useTranslate();
    return <span>{translate("resources.wevents.historyTitle")}</span>;
};


