import { List,
    AutocompleteInput,
    SimpleList, 
    Datagrid, 
    TextField,
    DateField,
    Edit, 
    Create,
    Show,
    SimpleForm,
    SelectField,
    BulkDeleteButton,
    TextInput,
    DateTimeInput,
    NumberInput,
    usePermissions,
    SimpleShowLayout,
    Labeled,
} from 'react-admin';
import * as Rights from './checkRights';
import { useMediaQuery } from '@mui/material';
import { StatusChoices } from './Constants'
import { NamedTitle } from './components/Title'
import { HistoryButtonToolbar } from './components/Toolbars'
import { Pagination500 } from './components/Pagination'

export const CamList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    return (
        <List 
            exporter={false}
            filters={customFilter} 
            sort={{ field: 'created_at', order: 'DESC' }}
            perPage={100}
            pagination={<Pagination500 />}
            >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.folder}
                    tertiaryText={record => <SelectField source="status" choices={StatusChoices} basePath="groups" record={record}/>}
                />
            ) : (
            <Datagrid 
                bulkActionButtons={Rights.check(permissions,Rights.EditCamSettings) ? <DefaultBulkActionButtons /> : false }
                rowClick={Rights.check(permissions,Rights.EditCamSettings) ? "edit" : "show"}>
                <TextField source="name" />
                <SelectField source="status" choices={StatusChoices}/>
                <TextField source="folder" />
                <TextField source="grabber_oid" sortable={false} />
                <TextField source="grabber_url" />
                <TextField source="video_retention_period" />
                <DateField source="created_at" locales="ru-RU" showTime />
                <DateField source="updated_at" locales="ru-RU" showTime />                
            </Datagrid> )}
        </List>
    )
};

const DefaultBulkActionButtons = props => (
    <>
        <BulkDeleteButton {...props} />
    </>
);

const customFilter = [
    <TextInput source="name" />,
    <AutocompleteInput source="status" choices={StatusChoices} />,
    <TextInput source="folder" />,
    <TextInput source="grabber_url" />,
    <DateTimeInput label={'filter.created_at_from'} source="created_at_from" />,
    <DateTimeInput label={'filter.created_at_to'} source="created_at_to" />,
    <DateTimeInput label={'filter.updated_at_from'} source="updated_at_from" />,
    <DateTimeInput label={'filter.updated_at_to'} source="updated_at_to" />,
];
//------------
export const CamEdit = () => {
    return <Edit title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCamSettings}/>} mutationMode='pessimistic'>
        <SimpleForm validate={validate}>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <AutocompleteInput source="status" choices={StatusChoices} />
            <TextInput source="folder" />
            <NumberInput source="grabber_oid" />
            <TextInput source="grabber_url" />
            <TextInput source="onvif_connect_string" />
            <NumberInput source="video_retention_period" />
            <Labeled>
                <DateField source="created_at" locales="ru-RU" showTime />
            </Labeled>
            <Labeled>
                <DateField source="updated_at" locales="ru-RU" showTime />
            </Labeled>
        </SimpleForm>
    </Edit>
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'resources.cams.nameRequired';
    }
    if (!values.grabber_oid) {
        errors.grabber_oid = 'resources.cams.paramRequired';
    }
    if (!values.grabber_url) {
        errors.grabber_url = 'resources.cams.paramRequired';
    }
    if (!values.onvif_connect_string) {
        errors.onvif_connect_string = 'resources.cams.paramRequired';
    }
    if (!values.video_retention_period) {
        errors.video_retention_period = 'resources.cams.paramRequired';
    }
    return errors
};
//------------
export const CamCreate = () => {
    return <Create redirect='list'>
        <SimpleForm validate={validate}>
            <TextInput source="name" />
            <TextInput source="folder" />
            <NumberInput source="grabber_oid" />
            <TextInput source="grabber_url" />
            <TextInput source="onvif_connect_string" />
            <NumberInput source="video_retention_period" />
        </SimpleForm>
    </Create>
};
//------------
export const CamShow = () => {
    return <Show title={<NamedTitle />} actions={<HistoryButtonToolbar checkRight={Rights.ViewCamSettings}/>}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <SelectField source="status" choices={StatusChoices}/>
            <TextField source="folder" />
            <TextField source="grabber_oid" />
            <TextField source="grabber_url" />
            <TextField source="onvif_connect_string" />
            <TextField source="video_retention_period" />
            <DateField source="created_at" locales="ru-RU" showTime />
            <DateField source="updated_at" locales="ru-RU" showTime />
        </SimpleShowLayout>
    </Show>
};



