// value 3 is deleted which never appears on front
export const StatusChoices = [
    { id: 1, name: 'misc.active' },
    { id: 2, name: 'misc.inactive' },
];

export const UserStatusChoices = [
    { id: 1, name: 'misc.active' },
    { id: 2, name: 'misc.inactive' },
    { id: 4, name: 'misc.notactivated' },
];

// Comm Settings
export const CommSettTypeChoices = [
    { id: 1, name: 'resources.communication_settings.notif' },
    { id: 2, name: 'resources.communication_settings.report' },
];

export const WeekdayChoices = [
    { id: 1, name: 'misc.monday' },
    { id: 2, name: 'misc.tuesday' },
    { id: 3, name: 'misc.wednesday' },
    { id: 4, name: 'misc.thursday' },
    { id: 5, name: 'misc.friday' },
    { id: 6, name: 'misc.saturday' },
    { id: 7, name: 'misc.sunday' },
];

export const MonthChoices = [
    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 },
    { id: 8 }, { id: 9 }, { id: 10 }, { id: 11 }, { id: 12 }, { id: 13 }, { id: 14 },
    { id: 15 }, { id: 16 }, { id: 17 }, { id: 18 }, { id: 19 }, { id: 20 }, { id: 21 },
    { id: 22 }, { id: 23 }, { id: 24 }, { id: 25 }, { id: 26 }, { id: 27 }, { id: 28 },
    { id: 29 }, { id: 30 }, { id: 31 },
];

export const ReportFreqChoices = [
    { id: 1, name: 'resources.communication_settings.reportFreqDaily' },
    { id: 2, name: 'resources.communication_settings.reportFreqWeekly' },
    { id: 3, name: 'resources.communication_settings.reportFreqMonthly' },
];

export const InteractionTypeChoices = [
    { id: 1, name: 'misc.email' },
    { id: 2, name: 'misc.sms' },
    { id: 3, name: 'misc.telegram' },
];

export const ReportTypeChoices = [
    { id: 1, name: 'resources.communication_settings.camEventsReport' },
];

// VEvents
export const TriggerTypeChoices = [
    { id: 1, name: 'misc.start' },
    { id: 2, name: 'misc.end' },
];

export const PresenceTypeChoices = [
    { id: 1, name: 'misc.present' },
    { id: 2, name: 'misc.notPresent' },
];