import React, { useState, useCallback } from 'react';
import { TextInput, useLocaleState, SimpleForm, useTranslate, useNotify, email, Title, SaveButton, Toolbar  } from 'react-admin';
import { useNavigate } from 'react-router';
import { httpClient } from './dataProvider';
import {
    Button,
    CardContent,
    Card,
} from '@mui/material';
import Box from '@mui/material/Box';

const CustomToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const UserSettings = () => {
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();
    const notify = useNotify();
    const user = JSON.parse(localStorage.getItem('w_user'));
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const clickHandler = (currentLocale, buttonLocale, setLocale) => {
        if (currentLocale === buttonLocale) {
            return
        } else {
            return setLocale(buttonLocale)
            .then(() => httpClient(`/api/v1/users/self`, {
                    method: 'PUT',
                    body: JSON.stringify({ locale: buttonLocale }),
                })
            )
            .then(({ json }) => {
                localStorage.setItem('w_user', JSON.stringify(json));
            })
            .catch(e => {
                return Promise.reject(e);
            });
        }
    };
    
    const handleSave = useCallback(values => {
        setLoading(true);
        const { first_name, middle_name, last_name, msisdn, email } = values;
        httpClient(`/api/v1/users/self`, {
            method: 'PUT',
            body: JSON.stringify({ first_name, middle_name, last_name, msisdn, email }),
        }).then(({ json }) => {
            setLoading(false);
            localStorage.setItem('w_user', JSON.stringify(json));
            notify('user.updateOk', { type: 'info' });
            navigate(-1);
        }).catch(e => {
            setLoading(false);
            notify('user.updateFail', { type: 'warning' });
            return Promise.reject(e);
        })
    });

    const msisdn = (value) => {
        if (value) {
            let error;
            let isnum = /^[1-9]\d{10,14}$/.test(value);
            if (!isnum) {
                error = 'user.msisdnError';
            }
            return error
        }
    };

    return (
            <SimpleForm onSubmit={handleSave} toolbar={<CustomToolbar />} record={user ? user : {}} >
                <Card fullWidth={true}>
                    <Title title={'user.settings'} />
                    <CardContent>
                    <Box display={{ md: 'flex', lg: 'flex' }} >
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '0.5em', lg: '1em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        >
                            <TextInput label={'resources.users.fields.login'} disabled fullWidth source="login" />
                        </Box>
                        <Box
                            flex={2}
                            mr={{ xs: 0, sm: '0.5em', lg: '1em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        />
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }} >
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '0.5em', lg: '1em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        >
                            <TextInput label={'user.first_name'} fullWidth source="first_name" />
                        </Box>
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '0.5em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        >    
                            <TextInput label={'user.middle_name'} fullWidth source="middle_name" />
                        </Box>
                        <Box
                            flex={1} 
                            sx={{ width: '10em', display: 'inline-block' }}
                        >
                            <TextInput label={'user.last_name'} fullWidth source="last_name" /> 
                        </Box>
                    </Box>
                    <Box display={{ md: 'flex', lg: 'flex' }} >
                        <Box
                            flex={1}
                            mr={{ xs: 0, sm: '0.5em', lg: '1em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        >
                            <TextInput label={'user.msisdn'} fullWidth source="msisdn" validate={msisdn}/>
                        </Box>
                        <Box
                            flex={2}
                            mr={{ xs: 0, sm: '0.5em', lg: '1em' }}
                            sx={{ width: '10em', display: 'inline-block' }}
                        >
                            <TextInput label={'user.email'} fullWidth source="email" type="email" validate={[email()]}/>
                        </Box>
                    </Box>
                        <Box sx={{ width: '10em', display: 'inline-block' }}>{translate('user.language')}</Box>
                        <Button
                            variant="contained"
                            sx={{ margin: '1em' }}
                            color={locale === 'ru' ? 'primary' : 'secondary'}
                            onClick={() => clickHandler(locale, 'ru', setLocale)}
                        >
                            ru
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ margin: '1em' }}
                            color={locale === 'en' ? 'primary' : 'secondary'}
                            onClick={() => clickHandler(locale, 'en', setLocale)}
                            disabled={loading}
                        >
                            en
                        </Button>
                    </CardContent>
                </Card>
            </SimpleForm>
    );
};