import {
    useRecordContext,
    useResourceContext,
    useTranslate,
} from 'react-admin';

export const NamedTitle = () => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    return <span>{translate(`resources.${resource}.title`)}{record ? `${record.name}` : ''}</span>;
};