import * as React from 'react';
import { AppBar, useTranslate, Logout, UserMenu, useUserMenu  } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const MyAppBar = props => (
    <AppBar {...props} userMenu={<MyUserMenu />} />
);

const MyUserMenu = () => {
    return (
        <UserMenu>
            <ConfigurationMenu />
            <Logout />
        </UserMenu>
    );
};

const ConfigurationMenu = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu();
    const translate = useTranslate();
    return <>
        <MenuItem 
            {...props}
            component={Link}
            to="/configuration"
            onClick={onClose}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
                {translate('button.configuration')}
            </ListItemText>
        </MenuItem>
        <MenuItem
            {...props}
            component={Link}
            to="/changepass"
            onClick={onClose}
        >
            <ListItemIcon>
                <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText>
                {translate('button.changePass')}
            </ListItemText>
        </MenuItem>
        </>
});

